import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import backgroundImage from "../../assets/images/auth-bg.jpg";
import config from "../../config";
import withRouter from "../../components/Common/WithRouter";
import { Col, Container, Row } from "react-bootstrap";
import * as Yup from "yup";
import { request } from "../../utils/Request";
import { passwordRegex } from "../../utils/Helper";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../components/Shared/InputField";
import { has } from "lodash";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

interface RESET_PASSWORD_FORM_STATE_TYPE {
    email: string | null
    username: string | null
    password: string | null
    password_confirmation: string | null
}

const DEFAULT_RESET_PASSWORD_FORM_STATE: RESET_PASSWORD_FORM_STATE_TYPE = {
    email: null,
    username: null,
    password: null,
    password_confirmation: null,
};

const validationSchema = (t: any) => Yup.object().shape({
    email: Yup.string().required(t("The field is required.", { field: "email" })).email(t("the email must be a valid email address.")).nullable(),
    username: Yup.string()
    .matches(
        /^(?:[a-zA-Z0-9._-]{3,25}|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b)$/,
        t("invalid username.")
    )
    .max(200, t("the field must not be greater than max.", { max: 200, field: "username" }))
    .required(t("the field is required.", { field: "username" }))
    .nullable(),
    password: Yup.string().required(t("The field is required.", { field: "password" }))
    .matches(passwordRegex,
        t("password must contain at least 8 characters, one uppercase, one number and one special case character", { field: "password" })
    )
    .nullable(),
    password_confirmation: Yup.string().required(t('the field is required.', { field: t('password confirmation') })).oneOf([Yup.ref('password'), null], t('the field confirmation does not match.', { field: t('password') })).nullable(),
});

const ResetPassword = (props: any) => {
    const { t } = props;
    const toast = useRef(null);
    document.title = `${ t("reset password!") } | ${ config.appNameForTitle }`;

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };


    const onSubmit = async (values: RESET_PASSWORD_FORM_STATE_TYPE, { setErrors, resetForm }: any): Promise<void> => {
        try {
            const response = await request({
                method: 'POST',
                url: `/reset/password`,
                data: {
                    ...values,
                    token: props.router.params['*']
                }
            });

            const { data } = response;
            resetForm();
            toastify(t("your password has been successfully updated.", { item: t('user') }), "info");
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    return (
        <React.Fragment>
            {
                <>
                    <Toast ref={ toast } />
                    <div className="auth-page">
                        <Container fluid className="p-0">
                            <Row className="g-0">
                                <Col lg={ 4 } md={ 5 } className="col-xxl-4">
                                    <div className="px-5 min-h-screen flex align-items-center">
                                        <div
                                            className="w-full border-1 surface-border surface-card border-round py-2 px-4 md:px-7 z-1">
                                            <div className="mb-0 mb-md-0 text-center">
                                                <Link to="/dashboard" className="d-block auth-logo">
                                                    <img
                                                        src={ `${ process.env.REACT_APP_API_URL }/logo/alcolm-logo-blue.png` }
                                                        height="80"
                                                        alt="Alcolm"
                                                    />{ " " }
                                                </Link>
                                            </div>

                                            <div className="mt-3">
                                                <div className="text-900 text-xl font-bold  text-center">
                                                    <h5>{ t("reset password!") }</h5>
                                                </div>
                                                <div className="text-600 font-medium text-center">
                                                    { t('reset password with') } { config.appName }
                                                </div>

                                                <div className={ "mt-4 pb-4" }>
                                                    <Formik
                                                        initialValues={ DEFAULT_RESET_PASSWORD_FORM_STATE }
                                                        validationSchema={ validationSchema(t) }
                                                        onSubmit={ onSubmit }
                                                    >
                                                        { (props: FormikProps<any>) => {
                                                            return (
                                                                <Form onSubmit={ props.handleSubmit }>
                                                                    <div className="mb-3">
                                                                        {
                                                                            has(props.errors, 'token')
                                                                            &&
                                                                            <div
                                                                              className={ "invalid-feedback first-letter-capitalized d-block text-center" }>
                                                                                {/*@ts-ignore*/ }
                                                                                { props.errors.token[0] }
                                                                            </div>
                                                                        }

                                                                        <Field component={ InputField }
                                                                               name="email"
                                                                               label={ t("email") }
                                                                               isRequired="true"
                                                                               placeholder={ t("enter") + ' ' + t("email").toLowerCase() }
                                                                               rounded
                                                                               autoFocus />

                                                                        <Field component={ InputField }
                                                                               name="username"
                                                                               label={ t("username") }
                                                                               isRequired="true"
                                                                               placeholder={ t("enter username") }
                                                                               rounded
                                                                               autoFocus />

                                                                        <Field component={ InputField }
                                                                               name="password"
                                                                               type={ "password" }
                                                                               label={ t("password") }
                                                                               placeholder={ t("enter") + ' ' + t("password").toLowerCase() }
                                                                               isRequired={ !props.values.id }
                                                                               rounded />

                                                                        <Field component={ InputField }
                                                                               name="password_confirmation"
                                                                               type={ "password" }
                                                                               label={ t("password confirmation") }
                                                                               placeholder={ t("enter confirm password") }
                                                                               isRequired={ !props.values.id }
                                                                               rounded />

                                                                        <div className="row mb-4">
                                                                            <div className="col">
                                                                                <div className="mt-3 d-grid">
                                                                                    <Button
                                                                                        className={ "w-full d-block" }
                                                                                        size={ 'small' }
                                                                                        type="submit"
                                                                                        disabled={ props.isSubmitting }
                                                                                    >
                                                                                        { t("reset") }
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            );
                                                        } }
                                                    </Formik>
                                                </div>


                                                <div className="mt-5 text-center">
                                                    <p className="text-muted mb-0">
                                                        { t("remember it ?") } { " " }
                                                        <Link
                                                            to="/login"
                                                            className="text-primary fw-semibold"
                                                        >
                                                            { " " }
                                                            { t("sign in") }{ " " }
                                                        </Link>{ " " }
                                                    </p>
                                                    <p className="text-muted mb-0">
                                                        { t("lost your password?") } { " " }
                                                        <Link
                                                            to="/forget/password"
                                                            className="text-primary fw-semibold"
                                                        >
                                                            { " " }
                                                            { t("recover it") }{ " " }
                                                        </Link>{ " " }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <div className="col-xxl-8 col-lg-8 col-md-7"
                                     style={ {
                                         backgroundImage: `url(${ backgroundImage })`,
                                         backgroundSize: "cover",
                                         backgroundPosition: 'center -50px',
                                         backgroundColor: '#f7f7f7',
                                         backgroundRepeat: "no-repeat"
                                     } }>
                                    &nbsp;
                                </div>
                            </Row>
                        </Container>
                    </div>
                </>
            }

        </React.Fragment>
    );
};

export default withTranslation()(withRouter(ResetPassword));

ResetPassword.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
