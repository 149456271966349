import React from 'react';
import { ErrorMessage } from "formik";
import { get, has } from "lodash";
import { InputText } from "primereact/inputtext";

interface IInputFieldProps {
    label?: string,
    withoutLabel?: boolean,
    field: any,
    form?: any,
    icon?: any,
    isRequired?: boolean
    autoFocus?: boolean
    formGroupClass?: any,
    inputGroupIconClass?: any,
    inputClass?: any,
    placeholder?: any,
    disabled?: boolean,
    invalid?: boolean,
    onBlur?: any,
    formText?: any,
    inputRef: any,
}

export class InputField extends React.Component<IInputFieldProps, {}> {
    static defaultProps = {
        isRequired: false,
        inputClass: "",
    };

    render() {
        const {
            field,
            form,
            isRequired,
            formGroupClass,
            invalid,
            withoutLabel,
            inputClass,
            formText,
            inputGroupIconClass,
            inputRef,
            ...props
        } = this.props;
        const { errors } = form;
        return (
            <div className="p-fluid">
                <div className={ `field ${ formGroupClass }` }>
                    { !withoutLabel
                        && <label htmlFor={ field.id ?? field.name }>
                            { props.label }
                            { isRequired && <strong style={ { color: 'var(--red-500)' } }> *</strong> }
                        </label>
                    }

                    {
                        inputGroupIconClass &&
                        <span className="p-input-icon-left">
                            <i className={ `${inputGroupIconClass}` } />
                            <InputText
                                { ...props }
                                name={ field.name }
                                id={ field.id ?? field.name }
                                value={ get(field, ['value']) === null ? "" : get(field, ['value']) }
                                onChange={ field.onChange }
                                className={ `${ inputClass } ${ has(errors, field.name) ? 'p-invalid' : '' } p-inputtext-sm` }
                                onFocus={ ($event: any) => $event.target.select() }
                                disabled={props.disabled ?? false}
                                autoComplete={"off"}
                                ref={ inputRef ?? null }
                                autoFocus={props.autoFocus ?? false}
                            />
                            { formText && <small>{ formText }</small> }
                            <ErrorMessage name={ field.name } component="small" className="p-error d-block" />
                        </span>
                    }

                    { !inputGroupIconClass &&
                        <>
                            <InputText
                                { ...props }
                                name={ field.name }
                                id={ field.id ?? field.name }
                                value={ get(field, ['value']) === null ? "" : get(field, ['value']) }
                                onChange={ field.onChange }
                                className={ `${ inputClass } ${ has(errors, field.name) ? 'p-invalid' : '' } p-inputtext-sm` }
                                onFocus={ ($event: any) => $event.target.select() }
                                disabled={props.disabled ?? false}
                                autoComplete={"off"}
                                ref={ inputRef ?? null }
                                autoFocus={props.autoFocus ?? false}
                            />
                            { formText && <small>{ formText }</small> }
                            <ErrorMessage name={ field.name } component="small"
                                          className="p-error  d-block" />
                        </>
                    }

                </div>
            </div>
        );
    }
}
