import React, { useState } from 'react';
import { customSplitter, giveUniqueColoredString, groupIdsByValue, utcDateToLocalDate } from "../../../../utils/Helper";
import { find, get, includes, indexOf, isEmpty, map, sortBy, uniq, uniqWith } from "lodash";
import AlpSpotLoadStatus from "../../alpSpotLoads/partials/AlpSpotLoadStatus";
import { Badge } from "primereact/badge";
import SpotLoadStatus from "../../spotLoads/partials/SpotLoadStatus";
import Location from "../../../../components/Common/Display/Location";
import VinWithType from "../../../../components/Common/Display/VinWithType";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

export function ColumnLoadNumber(props: any) {
    const { t }: any = props;
    const { load_number, created_at, type, load_type }: any = props.row;

    const grouped = (whole: string) => {
        const deliverable: any = [];
        if ( isEmpty(whole) ) {
            return deliverable;
        }
        map(whole.split("||"), (item: any) => {
            const tokens = item.split("___");

            deliverable.push({
                deliver_to: tokens[0],
                guid: tokens[1],
            });
        });

        return deliverable;
    };

    if ( type === "AlpSpotLoad" ) {
        const { alp_dealer_name, alp_supplier_compound, guid_colors } = props.row;
        const compounds: any = grouped(alp_supplier_compound);
        const dealers: any = grouped(alp_dealer_name);
        const colors: any = grouped(guid_colors);

        const load_types: any[] = [];
        map(load_type.split("||"), (item: any) => {
            const compound_guid = item.split("___");
            let deliver_name: string | null = null;


            if ( compound_guid[0] === "compound" ) {
                deliver_name = find(compounds, { guid: compound_guid[1] });
            }

            if ( compound_guid[0] === "dealer" ) {
                deliver_name = find(dealers, { guid: compound_guid[1] });
            }

            load_types.push({
                deliver_to: compound_guid[0],
                guid: compound_guid[1],
                deliver_name: get(deliver_name, ['deliver_to']),
                color: get(find(colors, { deliver_to: compound_guid[1] }), ['guid']),
            });
        });

        const allDeliveryTo = uniqWith(load_types, (x, y) => ( x.deliver_to === y.deliver_to && x.deliver_name === y.deliver_name ));

        return <>
            <div>
                <div><strong>{ load_number }</strong></div>
                <div>{ utcDateToLocalDate(created_at) }</div>
                <span>{ type.replace('Alp', 'EF ') }</span>
                { allDeliveryTo.map((item: any, index: number) => {
                    return <div key={ index }>
                        <div className={ "cursor-pointer" }>
                            <div>
                                <span className={ 'circle-indicator' } key={ index } style={ { color: item.color } }>
                                    <i className="text-xxs fas fa-circle"></i>
                                </span>
                                &nbsp;
                                { ( item.deliver_to === 'compound' ) &&
                                  <Tag value={ t("compound") } severity="success" className={ "text-capitalize" }
                                       style={ { fontSize: "0.5rem" } } /> }
                                { ( item.deliver_to === 'dealer' ) &&
                                  <Tag value={ t("dealer") } severity="info" className={ "text-capitalize" }
                                       style={ { fontSize: "0.5rem" } } /> }
                                { ( item.deliver_to === 'default' ) &&
                                  <Tag value={ t("default") } severity="warning" className={ "text-capitalize" }
                                       style={ { fontSize: "0.5rem" } } /> }
                                &nbsp;{ item.deliver_name }
                            </div>
                        </div>
                    </div>;
                }) }
            </div>
        </>
    }

    return <div>
        <div><strong>{ load_number }</strong></div>
        <div>{ utcDateToLocalDate(created_at) }</div>
        <span>{ type.replace('Alp', 'EF ') }</span>
        { type === "AlpInboundLoad" && <div>{ load_type }</div> }
    </div>;
}

export function ColumnRenderForDriver(props: any) {
    const { row }: any = props;
    const color = giveUniqueColoredString(row.guid_colors);
    const idsOrder = uniq(row.seq.split("||"));

    return <>
        {
            groupIdsByValue(
                sortBy(
                    customSplitter(
                        ( get(row, ['driver_name']) || "" )
                        .split("||"),
                        '___',
                        false
                    )
                    , (item: any) => indexOf(idsOrder, item.id))
            )
            .map((i: any, index: number) => {
                if ( isEmpty(i.value) ) {
                    return '';
                }

                return (
                    <div key={ index }>
                        <CircleComponent i={ i } color={ color } />
                        { i.value }
                    </div>
                );
            })
        }
    </>;
}

export function ColumnRenderForClient(props: any) {
    const { row, dropdowns }: any = props;
    const color = giveUniqueColoredString(row.guid_colors);
    const idsOrder = uniq(row.seq.split("||"));

    if ( row.type === "AlpSpotLoad" ) {
        const clients = get(( get(row, ['client_name']) || "" ).split("||"), ['0']);
        const client = get(clients.split("___"), [1]);

        return <>
            { get(find(dropdowns.clients, { id: client }), ['name']) }
        </>;
    }

    return <>
        {
            groupIdsByValue(
                sortBy(
                    customSplitter(
                        ( get(row, ['client_name']) || "" )
                        .split("||"),
                        '___',
                        false
                    )
                    , (item: any) => indexOf(idsOrder, item.id))
            )
            .map((i: any, index: number) => {
                if ( isEmpty(i.value) ) {
                    return '';
                }

                return (
                    <div key={ index }>
                        <CircleComponent i={ i } color={ color } />
                        { i.value }
                    </div>
                );
            })
        }
    </>;
};

export function ColumnRenderForBrandModel(props: any) {
    const { row, dropdowns }: any = props;
    const color = giveUniqueColoredString(row.guid_colors);
    const idsOrder = uniq(row.seq.split("||"));

    if ( row.type === "AlpSpotLoad" ) {
        return <>
            {
                groupIdsByValue(
                    sortBy(
                        customSplitter(
                            ( get(row, ['brand_model']) || "" )
                            .split("||"),
                            '___',
                            false
                        )
                        , (item: any) => indexOf(idsOrder, item.id))
                )
                .map((i: any, index: number) => {
                    const model = find(dropdowns.models, { id: i.value });
                    if ( isEmpty(i.value) || isEmpty(model) ) {
                        return '';
                    }

                    return (
                        <div key={ index }>
                            <CircleComponent i={ i } color={ color } />
                            { get(model, ['label']) }
                        </div>
                    );
                })
            }
        </>;
    }

    return <>
        {
            groupIdsByValue(
                sortBy(
                    customSplitter(
                        ( get(row, ['vin_number']) || "" )
                        .split("||"),
                        '___',
                        false
                    )
                    , (item: any) => indexOf(idsOrder, item.id))
            )
            .map((i: any, index: number) => {
                if ( isEmpty(i.value) ) {
                    return '';
                }
                const data = find(groupIdsByValue(
                    customSplitter(
                        ( get(row, ['brand_model']) || "" )
                        .split("||"),
                        '___',
                        false
                    )
                ), (item) => includes(item.ids, i.value));
                const model = find(dropdowns.models, { id: get(data, ['value']) })

                return (
                    <div key={ index }>
                        <CircleComponent i={ i } color={ row.guid_colors } />
                        { get(model, ['label']) }
                    </div>
                );
            })
        }
    </>;
}

export function ColumnRenderForTransportVehicle(props: any) {
    const { row }: any = props;

    // based on color
    const color = giveUniqueColoredString(row.guid_colors);
    const idsOrder = uniq(row.seq.split("||"));
    return <>
        {
            groupIdsByValue(
                sortBy(
                    customSplitter(
                        ( get(row, ['transport_vehicle']) || "" )
                        .split("||"),
                        '___',
                        false
                    )
                    , (item: any) => indexOf(idsOrder, item.id))
            )
            .map((i: any, index: number) => {
                if ( isEmpty(i.value) ) {
                    return '';
                }

                return (
                    <div key={ index }>
                        <CircleComponent i={ i } color={ color } />
                        { i.value.replaceAll("~~~", " | ") }
                    </div>
                );
            }) }
    </>;
}

export function ColumnRenderForLocation(props: any) {
    const { row, dropdowns }: any = props;
    const idsOrder = uniq(row.seq.split("||"));

    return <>
        {
            // sort by guid based on status_updated_at column
            sortBy(
                // split record by ||
                customSplitter(
                    ( get(row, ['locations']) || "" )
                    .split("||")
                )
                , (item: any) => indexOf(idsOrder, item.id))
            .map((i: any, index: number) => {
                // split from and to location by ~~~
                const locations = i.value.split("~~~");
                const fromLocation = find(dropdowns.locations, { id: get(locations, [0]) });
                const toLocation = find(dropdowns.locations, { id: get(locations, [1]) });

                return (
                    <div key={ index }>
                            <span className={ 'circle-indicator' }
                                  style={ { color: getColor(row.guid_colors, i.id) } }>
                                <i className="text-xxs fas fa-circle"></i>
                            </span>
                        <Location item={ fromLocation } titled={ 1 } />
                        <Location item={ toLocation } titled={ 1 } />
                    </div>
                );
            })
        }
    </>;
}

export function ColumnRenderForVin(props: any) {
    const { row, t }: any = props;
    const idsOrder = uniq(row.seq.split("||"));
    const [tooltip, setToolTip] = useState(t('click: to copy'));

    const handleCopy = (clipboard: string) => {
        navigator.clipboard.writeText(clipboard).then(() => {
            setToolTip(t('copied'));
            setTimeout(() => {
                setToolTip(t('click: to copy'));
            }, 5000);
        }).catch(() => {
            setToolTip(t('failed to copy text'));
        });
    };

    return <>
        {
            groupIdsByValue(
                sortBy(
                    customSplitter(
                        ( get(row, ['vin_number']) || "" )
                        .split("||"),
                        '___',
                        false
                    )
                    , (item: any) => indexOf(idsOrder, item.id))
            )
            .map((i: any, index: number) => {
                if ( isEmpty(i.value) ) {
                    return '';
                }

                return (
                    <div key={ index }>
                        <Button
                            className={ "p-button-x-sm" }
                            size={ "small" }
                            link
                            type={"button"}
                            tooltip={ tooltip }
                            tooltipOptions={{event: "both", position: "top"}}
                            style={{fontSize: '0.975rem'}}
                            onClick={ () => handleCopy(i.value) }>
                            <CircleComponent i={ i } color={ row.guid_colors } />
                            { i.value }
                        </Button>
                    </div>
                );
            })
        }
    </>;
}

export function ColumnRenderForQty(props: any) {
    const { row }: any = props;
    const idsOrder = uniq(row.seq.split("||"));

    return <>
        {
            groupIdsByValue(
                sortBy(
                    customSplitter(
                        ( get(row, ['vin_number']) || "" )
                        .split("||"),
                        '___',
                        false
                    )
                    , (item: any) => indexOf(idsOrder, item.id))
            ).length
        }
    </>;
}

export function ColumnRenderStatus(props: any) {
    const { t, row, onClickSpotLoadStatusUpdate }: any = props;

    if ( row.type === "AlpSpotLoad" ) {
        return (
            <strong className={ "text-uppercase text-xs" }>
                <AlpSpotLoadStatus status={ row.status } textable={ 1 } />
                {
                    (
                        !isEmpty(row.from_supplier_compound)
                        && row.status !== 'announced' && row.status !== 'rejected'
                        && isEmpty(row.gate_out_confirmed_at)
                    ) &&
                  <div className={ "mt-1" }>
                      <Badge color="danger" value={ t('gate out: pending') } />
                  </div>
                }
            </strong>
        );
    }

    if ( row.type === "SpotLoad" ) {
        const color = giveUniqueColoredString(row.guid_colors);
        const idsOrder = uniq(row.seq.split("||"));

        return (
            <span>
                    {
                        groupIdsByValue(
                            sortBy(
                                customSplitter(
                                    ( get(row, ['item_status']) || "" )
                                    .split("||"),
                                    '___',
                                    false
                                )
                                , (item: any) => indexOf(idsOrder, item.id))
                        )
                        .map((i: any, index: number) => {
                            if ( isEmpty(i.value) ) {
                                return '';
                            }
                            return (
                                <div key={ index } className={ ( index > 0 ) ? "mt-2" : "" }>
                                    {
                                        i.value === "pending"
                                        ? <strong className={ "text-uppercase text-xs" }>
                                            <CircleComponent i={ i } color={ color } />
                                            <SpotLoadStatus status={ i.value } textable={ 1 } />
                                        </strong>
                                        : <strong className={ "text-uppercase cursor-pointer underline text-xs" }
                                                  onClick={ () => onClickSpotLoadStatusUpdate(row) }>
                                            <CircleComponent i={ i } color={ color } />
                                            <SpotLoadStatus status={ i.value } textable={ 1 } />
                                        </strong>
                                    }
                                </div>
                            );
                        })
                    }
                </span>
        );
    }

    return ( <div>-</div> );
}

export function ColumnRenderInvoicedAt(props: any) {
    const { row }: any = props;

    if ( row.type === "AlpSpotLoad" ) {
        return <>{ utcDateToLocalDate(row.invoiced_at) }</>;
    }

    const color = giveUniqueColoredString(row.guid_colors);
    const idsOrder = uniq(row.seq.split("||"));

    return (
        <span>
                {
                    groupIdsByValue(
                        sortBy(
                            customSplitter(
                                ( get(row, ['invoiced_at']) || "" )
                                .split("||"),
                                '___',
                                false
                            )
                            , (item: any) => indexOf(idsOrder, item.id))
                    )
                    .map((i: any, index: number) => {
                        if ( isEmpty(i.value) ) {
                            return '';
                        }

                        return (
                            <div key={ index } className={ ( index > 0 ) ? "mt-2" : "" }>
                                <CircleComponent i={ i } color={ color } />
                                { utcDateToLocalDate(i.value) }
                            </div>
                        );
                    })
                }
            </span>
    );

    return ( <div>-</div> );
}

export function ColumnRenderForInvoiceNumber(props: any) {
    const { row }: any = props;

    if ( row.type === "AlpSpotLoad" ) {
        return row.invoice_number;
    }

    if ( row.type === "SpotLoad" ) {
        const color = giveUniqueColoredString(row.guid_colors);
        const idsOrder = uniq(row.seq.split("||"));

        return (
            <span>
                    {
                        groupIdsByValue(
                            sortBy(
                                customSplitter(
                                    ( get(row, ['invoice_number']) || "" )
                                    .split("||"),
                                    '___',
                                    false
                                )
                                , (item: any) => indexOf(idsOrder, item.id))
                        )
                        .map((i: any, index: number) => {
                            if ( isEmpty(i.value) ) {
                                return '';
                            }

                            return (
                                <div key={ index } className={ ( index > 0 ) ? "mt-2" : "" }>
                                    <CircleComponent i={ i } color={ color } />
                                    { i.value }
                                </div>
                            );
                        })
                    }
                </span>
        );
    }

    return ( <div>-</div> );
}

export function ColumnRenderInTransitAt(props: any) {
    const { row }: any = props;

    if ( row.type === "AlpSpotLoad" ) {
        return <div>{ utcDateToLocalDate(row.actual_loading_time) }</div>;
    }

    const idsOrder = uniq(row.seq.split("||"));

    return <>
        {
            sortBy(
                customSplitter(
                    ( get(row, ['in_transit_at']) || "" )
                    .split("||")
                )
                , (item: any) => indexOf(idsOrder, item.id))
            .map((i: any, index: number) => {
                if ( isEmpty(i.value) ) {
                    return '';
                }

                return (
                    <div key={ index }>
                            <span className={ 'circle-indicator' }
                                  style={ { color: getColor(row.guid_colors, i.id) } }>
                                <i className="text-xxs fas fa-circle"></i>
                            </span>
                        { utcDateToLocalDate(i.value) }
                    </div>
                );
            })
        }
    </>;
}

export function ColumnRenderDeliveredAt(props: any) {
    const { row }: any = props;

    if ( row.type === "AlpSpotLoad" ) {
        return <div>{ utcDateToLocalDate(row.actual_unloading_time) }</div>;
    }

    const idsOrder = uniq(row.seq.split("||"));

    return <>
        {
            sortBy(
                customSplitter(
                    ( get(row, ['delivered_at']) || "" )
                    .split("||")
                )
                , (item: any) => indexOf(idsOrder, item.id))
            .map((i: any, index: number) => {
                if ( isEmpty(i.value) ) {
                    return '';
                }

                return (
                    <div key={ index }>
                            <span className={ 'circle-indicator' }
                                  style={ { color: getColor(row.guid_colors, i.id) } }>
                                <i className="text-xxs fas fa-circle"></i>
                            </span>
                        { utcDateToLocalDate(i.value) }
                    </div>
                );
            })
        }
    </>;
}

export function getColor(guid_colors: any, id: string) {
    const colors = guid_colors.split("||");

    for (let i = 0; i < colors.length; i++) {
        const tokens: string[] = colors[i].split("___");
        if ( get(tokens, [0]) === id ) {
            return get(tokens, [1]);
        }
    }

    return '';
}

export function CircleComponent(row: any) {
    const { color, i } = row;

    return (
        <>
            { uniq(i.ids).map((idItem: any, idKey: number) => {
                const spanStyle = { color: getColor(color, idItem) };
                const colorValue = getColor(color, idItem);

                // Check if colorValue is truthy
                if ( colorValue ) {
                    return (
                        <span className={ 'circle-indicator' } key={ idKey } style={ spanStyle }>
                            <i className="text-xxs fas fa-circle"></i>
                        </span>
                    );
                } else {
                    // Return null if no value is returned from getColor function
                    return null;
                }
            }) }
        </>
    );
}

export function ColumnRenderForLegalEntity(props: any) {
    const { row, dropdowns }: any = props;
    const idsOrder = uniq(row.seq.split("||"));

    if ( row.type === "AlpSpotLoad" ) {
        const fromLocation = find(dropdowns.legalEntities, { id: get(row, ['legal_entity']) });

        return (
            <div>
                { get(fromLocation, ['label']) }
            </div>
        );
    }

    return <>
        {
            // sort by guid based on status_updated_at column
            sortBy(
                // split record by ||
                customSplitter(
                    (get(row, ['legal_entity']) || "")
                    .split("||")
                )
                , (item: any) => indexOf(idsOrder, item.id))
            .map((i: any, index: number) => {
                const locations = i.value.split("~~~");
                const fromLocation = find(dropdowns.legalEntities, {id: get(locations, [1])})

                return (
                    <div key={ index }>
                        <span className={ 'circle-indicator' }
                              style={ { color: getColor(row.guid_colors, i.id) } }>
                            <i className="text-xxs fas fa-circle"></i>
                        </span>
                        { get(fromLocation, ['label']) }
                    </div>
                )
            })
        }
    </>
}

export function legalEntityLabel(props: any) {
    const { row, dropdowns }: any = props;
    const idsOrder = uniq(row.seq.split("||"));

    return groupIdsByValue(
        sortBy(
            customSplitter(
                ( get(row, ['legal_entity']) || "" )
                .split("||"),
                '___',
                false
            )
            , (item: any) => indexOf(idsOrder, item.id))
    )
    .map((i: any, index: number) => {
        const model = find(dropdowns.legalEntities, { id: i.value });

        if ( isEmpty(i.value) || isEmpty(model) ) {
            return '';
        }

        return get(model, ['label']);
    })
    .filter((i: any) => !isEmpty(i))
    .join(',');
}
