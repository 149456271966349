import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";
import { Badge } from "primereact/badge";

const UserDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/users/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div className={ "p-4 bg-white" }>
            {
                entity &&
                <>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('first name') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.first_name }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('last name') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.last_name }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('email') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.email }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('username') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.username }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('status') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">
                                <>
                                    { ( entity.status > 0 ) && <Badge severity="success" value={ t('verified') } /> }
                                    { ( entity.status <= 0 ) && <Badge severity="danger" value={ t('unverified') } /> }
                                </>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('roles') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">
                                <>
                                    { ( entity.roles ) && entity.roles.map((value: any, index: number) => <Badge
                                        key={ index } severity={ 'info' } value={ value.name } />) }
                                </>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('phone') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.phone }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('status') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">
                                <>
                                    { ( entity.is_suspended > 0 ) && <Badge severity="success" value={ t('yes') } /> }
                                    { ( entity.is_suspended <= 0 ) && <Badge severity="danger" value={ t('no') } /> }
                                </>
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </div>

    );
};

UserDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(UserDetail));
