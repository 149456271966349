import React, { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProps } from "formik";
import config from "../../config";
import { request } from "../../utils/Request";
import Auth from "../../utils/Auth";
import { get } from "lodash";
import withRouter from "../../components/Common/WithRouter";
import { InputField } from "../../components/Shared/InputField";
import { Button } from "primereact/button";
import { LayoutContext } from "../../contexts/LayoutContext";
import { AUTH_LOGIN } from "../../store/actions/auth";
import { useDispatch } from "react-redux";

interface OTP_VERIFICATION_STATE_TYPE {
    otp: string | null,
    agree: boolean,
}

const DEFAULT_OTP_VERIFICATION_STATE: OTP_VERIFICATION_STATE_TYPE = {
    otp: null,
    agree: true
};

const validationSchema = (t: any) => Yup.object().shape({
    otp: Yup.string().required(t("the field is required.", { field: "otp" })).nullable(),
    agree: Yup.boolean()
});

const ResendOtp = (props: any) => {
    const [timer, setTimer] = useState(60);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (isDisabled) {
            const interval = setInterval(() => {
                setTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(interval);
                        setIsDisabled(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isDisabled]);

    const handleResendClick = async (): Promise<void> => {
        try {
            const response = await request({
                method: 'POST',
                url: `/otp/send`,
                data: { id: props.id },
                withoutAuth: 1
            });

            setIsDisabled(true);
            setTimer(60);

        } catch (e: any) {

        }
    };

    return (
        <Button label={ props.t("resend") }
            type={ "button" } severity={ "warning" }
            onClick={ handleResendClick }
            className={ "w-full" } size={ "small" }
            disabled={isDisabled || props.isDisabled}
            loading={ props.isDisabled }>
            {isDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
        </Button>
    );
};

const LoginOtpVerification = (masterProps: any) => {
    const formRef: any = useRef();
    const dispatch = useDispatch();
    const [initFormState, _] = useState<OTP_VERIFICATION_STATE_TYPE>(DEFAULT_OTP_VERIFICATION_STATE);
    const { t } = masterProps;
    const auth = new Auth();
    const {setLayoutConfig} = useContext(LayoutContext);

    document.title = `${ t("Login") } | ${ config.appNameForTitle }`;

    const onSubmit = async (values: OTP_VERIFICATION_STATE_TYPE, { setErrors }: any): Promise<void> => {
        try {
            const response = await request({
                method: 'POST',
                url: `/otp/verify`,
                data: { ...values, id: masterProps.id },
                withoutAuth: 1
            });

            const { data } = response;
            auth.setUser(data.data);
            dispatch({ type: AUTH_LOGIN });
            setLayoutConfig(data.data.app_settings);
            setTimeout(() => window.location.href = '/dashboard', 500);
        } catch (e: any) {
            setErrors(
                {otp: [t("whoops! seems like otp is not valid")]}
            );
        }
    };

    const handleTermsChange = (e: any) => {
        const base: any = get(formRef, ['current']);
        if ( base ) {
            base.setFieldValue('agree', e);
        }
    };

    useEffect(() => {

    }, []);

    return (
        <React.Fragment>
            <Formik
              innerRef={ formRef }
              enableReinitialize={ true }
              initialValues={ initFormState }
              validationSchema={ validationSchema(t) }
              onSubmit={ onSubmit }
            >
                { (props: FormikProps<any>) => {
                    return (
                        <Form onSubmit={ props.handleSubmit }>
                            <Field component={ InputField }
                                   name="otp"
                                   label={ t("please enter the otp to continue") }
                                   isRequired="true"
                                   placeholder={ t("enter") +' '+ t("otp") }
                                   autoComplete="off"
                                   formText={ t("we have sent an otp to your email address") }
                                   autoFocus />

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    name={ "agree" }
                                    type="checkbox"
                                    id="terms"
                                    checked={ props.values.agree }
                                    onChange={ (e: any) => handleTermsChange(e.target.checked) }
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="terms"
                                >
                                    { t("by logging in, i agree to the") }
                                    <a href="https://alcolm.com/docs/PrivacyPolicyCloudWervicesEN.pdf"
                                       target={ "_blank" }
                                       rel="noreferrer">
                                        &nbsp; { t("privacy policy") }&nbsp;
                                    </a>
                                    { t('and') }
                                    <a href={ `${ process.env.REACT_APP_API_URL }/terms/terms.pdf` }
                                       target={ "_blank" }
                                       rel="noreferrer">
                                        &nbsp; { t('terms & conditions') }
                                    </a>
                                </label>
                            </div>

                            <div className={ "mt-3" }>
                                <Button label={ t("verify") }
                                        type={ "submit" }
                                        disabled={ props.isSubmitting || !props.values.agree }
                                        className={ "w-full" }
                                        size={ "small" }
                                        loading={ props.isSubmitting }
                                />
                            </div>

                            <div className={ "mt-3" }>
                                <ResendOtp t={ t }
                                           isDisabled={ props.isSubmitting || !props.values.agree }
                                           id={ masterProps.id } />
                            </div>
                        </Form>
                    );
                } }
            </Formik>
        </React.Fragment>
    );
};

export default withTranslation()(withRouter(LoginOtpVerification));

LoginOtpVerification.propTypes = {
    history: PropTypes.object,
    id: PropTypes.string,
    t: PropTypes.any,
};
