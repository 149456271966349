import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes, isEmpty, orderBy, reverse } from "lodash";
import { request } from "../../../../utils/Request";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import { useSearchParams } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import Location from "../../../../components/Common/Display/Location";
import AlpSpotLoadStatus from "./AlpSpotLoadStatus";
import VinWithType from "../../../../components/Common/Display/VinWithType";
import { Tag } from "primereact/tag";

const AlpSpotLoadDetail = (props: any) => {
        const { t, toastify, params } = props;
        const [entity, setEntity] = useState<any>(null);
        const [searchParams] = useSearchParams();
        const [activeIndex, setActiveIndex] = useState(0);

        const getDetail = async (id: string) => {
            try {
                const response = await request({
                    url: `/suppliers/alp/spot/loads/${ id }`
                });

                const { data } = response.data;
                setEntity(data);
            } catch (error) {
                toastify(t("server error"), "error");
            }
        };

        const onClose = () => {
            // make sure back_to and back_with are not blank and redirect with all parameters
            if ( !isEmpty(searchParams.get('back_to')) ) {
                // @ts-ignore
                // parameters will have r as a prefix so remove it first
                props.router.navigate(`/${ searchParams.get('back_to') }`);
                return;
            }

            props.onClose();
        };

        useEffect(() => {
            if ( includes(['detail'], get(params, ['operation'])) ) {
                getDetail(params.operationId);
            }
        }, [params]);

        return (
            <div className={ "p-4 bg-white" }>
                {
                    entity &&
                    <>
                        <Row>
                            {
                                entity &&
                                <div>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('load number') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end text-uppercase">
                                                { entity.load_number }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('status') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end text-uppercase">
                                                <AlpSpotLoadStatus
                                                  status={ entity.status } />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('created at') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end text-uppercase">
                                                { utcDateToLocalDate(entity.created_at) }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('deliver to') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end text-uppercase">
                                                { entity.deliver_to }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span
                                                  className="text-capitalize">{ t('compound company') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.supplier_compound }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('dealer') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.dealer_name }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span
                                                  className="text-capitalize">{ t('transport vehicle') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.truck_plate_number } { entity.trailer_plate_number && ` | ${ entity.trailer_plate_number }` }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('driver') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.driver_name }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span
                                                  className="text-capitalize">{ t('gate out confirmed') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { utcDateToLocalDate(entity.gate_out_confirmed_at) }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span
                                                  className="text-capitalize">{ t('estimated loading') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.estimated_loading_time && utcDateToLocalDate(entity.estimated_loading_time) }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span
                                                  className="text-capitalize">{ t('actual loading') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.actual_loading_time && utcDateToLocalDate(entity.actual_loading_time) }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span
                                                  className="text-capitalize">{ t('estimated unloading') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.estimated_unloading_time && utcDateToLocalDate(entity.estimated_unloading_time) }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span
                                                  className="text-capitalize">{ t('actual unloading') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.actual_unloading_time && utcDateToLocalDate(entity.actual_unloading_time) }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('price') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.a_s_price }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span
                                                  className="text-capitalize">{ t('is billable?') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { !isEmpty(entity.invoiceable_at) ? t("yes") : t("no") }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('is billed?') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { !isEmpty(entity.invoiced_at) ? t("yes") : t("no") }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('cmr') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.cmr }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <TabView activeIndex={ activeIndex }
                                                 onTabChange={ (e) => setActiveIndex(e.index) }>
                                            <TabPanel header={ t('orders') }>
                                                <div className="table-responsive">
                                                    <table className={ "fl-table w-full" }>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{ t('deliver to') }</th>
                                                                <th>{ t('from supplier compound') }</th>
                                                                <th>{ t('location') }</th>
                                                                <th>{ t('vin number') }</th>
                                                                <th>{ t('brand') }</th>
                                                                <th>{ t('status') }</th>
                                                                <th>{ t('price') }</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                entity.alp_spot_load_orders.map((order: any, orderIndex: number) => {
                                                                    return (
                                                                        <tr key={ orderIndex }>
                                                                            <td>{ orderIndex + 1 }</td>
                                                                            <td>
                                                                                { ( order.deliver_to === 'compound' ) &&
                                                                                  <Tag value={ t("compound") }
                                                                                       severity="success"
                                                                                       className={ "text-capitalize" }
                                                                                       style={ { fontSize: "0.5rem" } } /> }
                                                                                { ( order.deliver_to === 'dealer' ) &&
                                                                                  <Tag value={ t("dealer") }
                                                                                       severity="info"
                                                                                       className={ "text-capitalize" }
                                                                                       style={ { fontSize: "0.5rem" } } /> }
                                                                                { ( order.deliver_to === 'default' ) &&
                                                                                  <Tag value={ t("default") }
                                                                                       severity="warning"
                                                                                       className={ "text-capitalize" }
                                                                                       style={ { fontSize: "0.5rem" } } /> }
                                                                                <div> { order.supplier_compound } </div>
                                                                                <div> { order.dealer_name } </div>
                                                                            </td>
                                                                            <td>{ order.from_supplier_compound }</td>
                                                                            <td>
                                                                                <div>
                                                                                    <Location
                                                                                        item={ order.from_location }
                                                                                        titled={ 1 } />
                                                                                    <div><Location
                                                                                        item={ order.to_location }
                                                                                        titled={ 1 } /></div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <VinWithType
                                                                                    vinNumber={ get(order, ['order', 'vin_number']) } />
                                                                                <div>{ get(order, ['order', 'order_cd']) }</div>
                                                                            </td>
                                                                            <td>
                                                                                        <span
                                                                                            className={ "text-capitalize" }>
                                                                                              { ( get(order, ['order', 'brand', 'title']) ) }
                                                                                            <span
                                                                                                className={ "text-capitalize d-block" }>
                                                                                                   { get(order, ['order', 'brand_model', 'title']) }
                                                                                                - { get(order, ['order', 'brand_model', 'version_code']) }
                                                                                              </span>
                                                                                          </span>
                                                                            </td>
                                                                            <td>
                                                                                <span>{ `${ order.status }`.toUpperCase() }</span>
                                                                            </td>
                                                                            <td>
                                                                                <span>{ order.input_price && order.input_price }</span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPanel>
                                            <TabPanel header={ t('status histories') }>
                                                <div className="table-responsive">
                                                    <table className={ "fl-table w-full" }>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{ t('author') }</th>
                                                                <th>{ t('status') }</th>
                                                                <th>{ t('comments') }</th>
                                                                <th>{ t('created at') }</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                entity.status_histories && orderBy(entity.status_histories, ['created_at'], ['desc']).map((history: any, historyIndex: number) => {
                                                                    return (
                                                                        <tr key={ historyIndex }>
                                                                            <td>{ historyIndex + 1 }</td>
                                                                            <td>
                                                                            { get(history, ['author', 'name']) }
                                                                            </td>
                                                                            <td>
                                                                                        <span
                                                                                            className={ "text-uppercase" }>{ history.status }</span>
                                                                            </td>
                                                                            <td>
                                                                                { history.comments }
                                                                                <div>{ history.system_note }</div>
                                                                            </td>
                                                                            <td>
                                                                                { utcDateToLocalDate(history.created_at) }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPanel>
                                            <TabPanel header={ t('status history from alp') }>
                                                <div className="table-responsive">
                                                    <table className={ "fl-table w-full" }>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{ t('author') }</th>
                                                                <th>{ t('status') }</th>
                                                                <th>{ t('comments') }</th>
                                                                <th>{ t('created at') }</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                entity.alp_status_histories && orderBy(entity.alp_status_histories, ['created_at'], ['desc']).map((history: any, historyIndex: number) => {
                                                                    return (
                                                                        <tr key={ historyIndex }>
                                                                            <td>{ historyIndex + 1 }</td>
                                                                            <td>{ get(history, ['author']) }</td>
                                                                            <td>
                                                                                <span
                                                                                    className={ "text-uppercase" }>{ history.status }</span>
                                                                            </td>
                                                                            <td>{ history.comments }</td>
                                                                            <td>{ utcDateToLocalDate(history.created_at) }</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPanel>
                                        </TabView>
                                    </Row>
                                </div>
                            }
                        </Row>
                    </>
                }
            </div>

        );
    }
;

AlpSpotLoadDetail.propTypes =
    {
        // t: PropTypes.any,
    }
;

export default withTranslation()(WithRouter(AlpSpotLoadDetail));
