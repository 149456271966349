import { MenuModel } from "../../../types";

export function AdminNav(props: any) {
    const { t, auth } = props;
    let items: any = [
        {
            label: t("dashboard"),
            icon: "pi pi-fw pi-home",
            to: "/dashboard",
            key: 1,
        },
    ];

    let model: MenuModel[] = [
        {
            label: '',
            icon: "pi pi-home",
            items: items,
            key: 2,
        },
    ];

    if ( auth.hasAnyAccess(['suppliersview']) ) {
        items = [
            ...items,
            {
                label: props.t('access management'),
                icon: "pi pi-fw pi-car",
                key: 3,
                items: [
                    auth.hasAnyAccess(['suppliersview']) ? {
                        label: t("accounts"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/accounts",
                        key: 4,
                    } : {},
                    auth.hasAnyAccess(['suppliersview']) ? {
                        label: t("registration requests"),
                        icon: "pi pi-fw pi-circle-fill",
                        to: "/registration/requests",
                        key: 5,
                    } : {},
                ],
            }
        ];
    }

    model[0].items = items;

    return model;
}
