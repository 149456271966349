import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProps } from "formik";
import backgroundImage from "../../assets/images/auth-bg.jpg";
import config from "../../config";
import { request } from "../../utils/Request";
import { get } from "lodash";
import withRouter from "../../components/Common/WithRouter";
import { Col, Container, Row } from "react-bootstrap";
import { InputField } from "../../components/Shared/InputField";
import { Button } from "primereact/button";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import RegistrationOtpVerification from "./RegistrationOtpVerification";
import { Divider } from "primereact/divider";
import Tooltip from "../../components/Common/Display/Tooltip";
import engImg from "../../assets/images/flags/us.svg";
import grImg from "../../assets/images/flags/gr.svg";
import rsImg from "../../assets/images/flags/rs.svg";
import itImg from "../../assets/images/flags/it.svg";
import spImg from "../../assets/images/flags/sp.svg";
import slImg from "../../assets/images/flags/sl.svg";
import { InputSingleSelectField } from "../../components/Shared/InputSingleSelectField";

interface ACCOUNT_REQUEST_FORM_STATE_TYPE {
    first_name: string | null,
    last_name: string | null,
    company_name: string | null,
    country: string | null,
    phone: string | null,
    email: string | null,
    agree: boolean,
    share_data: boolean,
    communication_mail: boolean,
}

const DEFAULT_ACCOUNT_REQUEST_FORM_STATE: ACCOUNT_REQUEST_FORM_STATE_TYPE = {
    first_name: null,
    last_name: null,
    company_name: null,
    country: null,
    phone: null,
    email: null,
    agree: true,
    share_data: true,
    communication_mail: true
};

const validationSchema = (t: any) => Yup.object().shape({
    first_name: Yup.string()
    .max(191, t("the field must not be greater than max.", { max: 191, field: t("first name") }))
    .required(t("the field is required.", { field: "first name" }))
    .nullable(),
    last_name: Yup.string()
    .max(191, t("the field must not be greater than max.", { max: 191, field: t("last name") }))
    .required(t("the field is required.", { field: "last name" }))
    .nullable(),
    company_name: Yup.string()
    .max(255, t("the field must not be greater than max.", { max: 255, field: t("company name") }))
    .required(t("the field is required.", { field: "company name" }))
    .nullable(),
    email: Yup.string().required(t("the field is required.", { field: "email" })).nullable(),
    country: Yup.string().required(t("the field is required.", { field: "country" })).nullable(),
    phone: Yup
    .string()
    .matches(/^\+\d+$/, t("enter a valid phone number (e.g., +411236789787)"))
    .required(t("the field is required.", { field: "phone" })).nullable(),
    // country: Yup.string().required(t("the field is required.", { field: "country" })).nullable(),
    agree: Yup.boolean(),
    share_data: Yup.boolean(),
    communication_mail: Yup.boolean(),
});

const DEFAULT_DROPDOWNS = {
    countries: [],
};

const RegistrationRequest = (props: any) => {
    const formRef: any = useRef();
    const { i18n } = useTranslation();
    const [visible, setVisible] = useState<boolean>(false);
    const [userIdForOtp, setUserIdForOtp] = useState<string|null>(null);
    const [isCompleteVerification, setCompleteVerification] = useState<boolean>(false);
    const [capchaStatus, setCapchaStatus] = useState<string|null>(null);
    const [initFormState, _] = useState<ACCOUNT_REQUEST_FORM_STATE_TYPE>(DEFAULT_ACCOUNT_REQUEST_FORM_STATE);
    const { t } = props;
    const [dropdowns, setDropdowns] = useState<any>(null);

    document.title = `${ t("account request") } | ${ config.appNameForTitle }`;

    const onSubmit = async (values: ACCOUNT_REQUEST_FORM_STATE_TYPE, { setErrors }: any): Promise<void> => {
        try {
            const response = await request({
                method: 'POST',
                url: `a/registration/request`,
                data: { ...values, capcha: capchaStatus },
                withoutAuth: 1
            });

            const { data } = response;
            setUserIdForOtp(data.data.id);
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            }
        }
    };

    const handleTermsChange = (e: any) => {
        const base: any = get(formRef, ['current']);
        if ( base ) {
            base.setFieldValue('agree', e);
        }
    };

    const handleShareDataChange = (e: any) => {
        const base: any = get(formRef, ['current']);
        if ( base ) {
            base.setFieldValue('share_data', e);
        }
    };

    const handleCommunicationMailChange = (e: any) => {
        const base: any = get(formRef, ['current']);
        if ( base ) {
            base.setFieldValue('communication_mail', e);
        }
    };

    const handleVerificationSuccess = (token: string, ekey: string) => {
        setCapchaStatus(token)
    }

    const getDropdowns = async () => {
        try {
            const response = await request({
                url: '/a/registration/request/create'
            });

            const { data } = response.data;
            setDropdowns(data);
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        } finally {
            setVisible(true);
            setUserIdForOtp(null);
        }
    };

    useEffect(() => {
        async function init() {
            await getDropdowns();
        }

        init();
    }, []);

    const onClickLangChange = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    return (
        <React.Fragment>
            {
                visible &&
                <>
                    <div className="auth-page">
                        <Container fluid className="p-0">
                            <Row className="g-0">
                                <Col lg={ 4 } md={ 5 } className="col-xxl-4">
                                    <div className="px-5 min-h-screen flex align-items-center">
                                        <div
                                          className="w-full border-1 surface-border surface-card border-round py-2 px-4 md:px-7 z-2">
                                            <div className="mb-0 mb-md-0 text-center">
                                                <Link to="/dashboard" className="d-block auth-logo">
                                                    <img
                                                      src={ `${ process.env.REACT_APP_API_URL }/logo/alcolm-logo-blue.png` }
                                                      loading={ "eager" }
                                                      height="80"
                                                      alt="Alcolm"
                                                    />{ " " }
                                                </Link>
                                            </div>

                                            {
                                                !isCompleteVerification
                                                    ? <>
                                                        <div className="mt-3 text-center">
                                                        <span className="text-600 font-medium ">
                                                            {
                                                                !userIdForOtp
                                                                    ? t("provide the necessary details")
                                                                    : t("please confirm otp")
                                                            }
                                                        </span>
                                                        </div>
                                                        <div className={ "mt-4 pb-4" }>
                                                            {
                                                                !userIdForOtp ?
                                                                    <>
                                                                        <Formik
                                                                            innerRef={ formRef }
                                                                            enableReinitialize={ true }
                                                                            initialValues={ initFormState }
                                                                            validationSchema={ validationSchema(t) }
                                                                            onSubmit={ onSubmit }
                                                                        >
                                                                            { (props: FormikProps<any>) => {
                                                                                const { errors } = props;
                                                                                return (
                                                                                    <Form onSubmit={ props.handleSubmit }>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <Field
                                                                                                    component={ InputField }
                                                                                                    name="first_name"
                                                                                                    label={ t("first name") }
                                                                                                    isRequired="true"
                                                                                                    placeholder={ t("enter") + ' ' + t("first name") }
                                                                                                    autoComplete="off"
                                                                                                    autoFocus />
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <Field
                                                                                                    component={ InputField }
                                                                                                    name="last_name"
                                                                                                    label={ t("last name") }
                                                                                                    isRequired="true"
                                                                                                    placeholder={ t("enter") + ' ' + t("last name") }
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>

                                                                                        <Field component={ InputField }
                                                                                               name="email"
                                                                                               label={ t("email") }
                                                                                               isRequired="true"
                                                                                               placeholder={ t("enter") + ' ' + t("email") }
                                                                                               autoComplete="off"
                                                                                        />

                                                                                        <Row>
                                                                                            <Col>
                                                                                                <Field
                                                                                                    component={ InputField }
                                                                                                    name="company_name"
                                                                                                    label={ t("company name") }
                                                                                                    isRequired="true"
                                                                                                    placeholder={ t("enter") + ' ' + t("company name") }
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <Field
                                                                                                    component={ InputField }
                                                                                                    name="phone"
                                                                                                    label={ t("phone") }
                                                                                                    isRequired="true"
                                                                                                    placeholder={ t("enter") + ' ' + t("phone") }
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>

                                                                                        <Field
                                                                                            component={ InputSingleSelectField }
                                                                                            name="country"
                                                                                            trackBy="id"
                                                                                            options={ dropdowns.countries }
                                                                                            label={ t('country') }
                                                                                            placeholder={ t('pick') + ' ' + t('country') }
                                                                                            panelClassName={ "hide-p-toggler" }
                                                                                            isRequired
                                                                                        />


                                                                                        <div
                                                                                            className={ "text-center mb-2" }>
                                                                                            <HCaptcha
                                                                                                onChalExpired={ props.resetForm }
                                                                                                sitekey="bf5079a6-8312-4a88-a9ee-654e3cb2cb0e"
                                                                                                onVerify={ (token: string, ekey: string) => handleVerificationSuccess(token, ekey) }
                                                                                            />
                                                                                            <small className={ "p-error" }>
                                                                                                { get(errors, ['capcha', '0']) }
                                                                                            </small>
                                                                                        </div>

                                                                                        <div className="form-check">
                                                                                            <input
                                                                                                className="form-check-input d-inline"
                                                                                                name={ "agree" }
                                                                                                type="checkbox"
                                                                                                id="terms"
                                                                                                checked={ props.values.agree }
                                                                                                onChange={ (e: any) => handleTermsChange(e.target.checked) }
                                                                                            />
                                                                                            <div className={ "d-inline" }>
                                                                                                { t("by registering in, i agree to the") }
                                                                                                <a href="https://alcolm.com/docs/PrivacyPolicyCloudWervicesEN.pdf"
                                                                                                   target={ "_blank" }
                                                                                                   rel="noreferrer">
                                                                                                    &nbsp; { t("privacy policy") }&nbsp;
                                                                                                </a>
                                                                                                { t('and') }
                                                                                                <a href={ `${ process.env.REACT_APP_API_URL }/terms/terms.pdf` }
                                                                                                   target={ "_blank" }
                                                                                                   rel="noreferrer">
                                                                                                    &nbsp; { t('terms & conditions') }
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check">
                                                                                            <input
                                                                                                className="form-check-input d-inline"
                                                                                                name={ "share_data" }
                                                                                                type="checkbox"
                                                                                                id="share_data"
                                                                                                checked={ props.values.share_data }
                                                                                                onChange={ (e: any) => handleShareDataChange(e.target.checked) }
                                                                                            />
                                                                                            <div className={ "d-inline" }>
                                                                                                { t("i agree to allow alcolm to store and process my personal data.*") }
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check">
                                                                                            <input
                                                                                                className="form-check-input d-inline"
                                                                                                name={ "communication_mail" }
                                                                                                type="checkbox"
                                                                                                id="communication_mail"
                                                                                                checked={ props.values.communication_mail }
                                                                                                onChange={ (e: any) => handleCommunicationMailChange(e.target.checked) }
                                                                                            />
                                                                                            <div className={ "d-inline" }>
                                                                                                { t("i agree to receive other communications from alcolm") }
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className={ "mt-3" }>
                                                                                            <Button
                                                                                                label={ t("submit request") }
                                                                                                type={ "submit" }
                                                                                                disabled={ props.isSubmitting || !props.values.agree || !capchaStatus }
                                                                                                className={ "w-full" }
                                                                                                size={ "small" }
                                                                                                loading={ props.isSubmitting }
                                                                                            />
                                                                                        </div>

                                                                                        <Divider align="center">
                                                                                            <span
                                                                                                className="p-tag">{ t("change language") }</span>
                                                                                        </Divider>

                                                                                        <Row>
                                                                                            <Col
                                                                                                className={ "text-center" }>
                                                                                                <Tooltip
                                                                                                    tooltip={ t('click: to switch language to english') }>
                                                                                                    <a
                                                                                                        className="cursor-pointer p-2"
                                                                                                        onClick={ () => onClickLangChange("eng") }>
                                                                                                        <img src={ engImg }
                                                                                                             alt="English"
                                                                                                             width={ 30 }
                                                                                                             height={ 20 } />
                                                                                                    </a>
                                                                                                </Tooltip>
                                                                                                <Tooltip
                                                                                                    tooltip={ t('click: to switch language to german') }>
                                                                                                    <a
                                                                                                        className="cursor-pointer p-2"
                                                                                                        onClick={ () => onClickLangChange("gr") }>
                                                                                                        <img src={ grImg }
                                                                                                             alt="German"
                                                                                                             width={ 30 }
                                                                                                             height={ 20 } />
                                                                                                    </a>
                                                                                                </Tooltip>
                                                                                                {/*<Tooltip*/}
                                                                                                {/*    tooltip={ t('click: to switch language to russian') }>*/}
                                                                                                {/*    <a*/}
                                                                                                {/*        className="cursor-pointer p-2"*/}
                                                                                                {/*        onClick={ () => onClickLangChange("rs") }>*/}
                                                                                                {/*        <img src={ rsImg }*/}
                                                                                                {/*             alt="Russian"*/}
                                                                                                {/*             width={ 30 }*/}
                                                                                                {/*             height={ 20 } />*/}
                                                                                                {/*    </a>*/}
                                                                                                {/*</Tooltip>*/}
                                                                                                <Tooltip
                                                                                                    tooltip={ t('click: to switch language to italian') }>
                                                                                                    <a
                                                                                                        className="cursor-pointer p-2"
                                                                                                        onClick={ () => onClickLangChange("it") }>
                                                                                                        <img src={ itImg }
                                                                                                             alt="Italian"
                                                                                                             width={ 30 }
                                                                                                             height={ 20 } />
                                                                                                    </a>
                                                                                                </Tooltip>
                                                                                                <Tooltip
                                                                                                    tooltip={ t('click: to switch language to spanish') }>
                                                                                                    <a
                                                                                                        className="cursor-pointer p-2"
                                                                                                        onClick={ () => onClickLangChange("sp") }>
                                                                                                        <img src={ spImg }
                                                                                                             alt="Spanish"
                                                                                                             width={ 30 }
                                                                                                             height={ 20 } />
                                                                                                    </a>
                                                                                                </Tooltip>
                                                                                                <Tooltip
                                                                                                    tooltip={ t('click: to switch language to slovenian') }>
                                                                                                    <a
                                                                                                        className="cursor-pointer p-2"
                                                                                                        onClick={ () => onClickLangChange("sl") }>
                                                                                                        <img src={ slImg }
                                                                                                             alt="Slovenian"
                                                                                                             width={ 30 }
                                                                                                             height={ 20 } />
                                                                                                    </a>
                                                                                                </Tooltip>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Form>
                                                                                );
                                                                            } }
                                                                        </Formik>
                                                                    </> :
                                                                    <RegistrationOtpVerification
                                                                        id={ userIdForOtp }
                                                                        handleComplete={ () => setCompleteVerification(true) }
                                                                    />
                                                            }

                                                        </div>
                                                    </>
                                                    : <>
                                                        <div className="text-900 text-xl font-bold  text-center mt-5">
                                                            <h5>
                                                                { t("thank you for your interest!") }
                                                            </h5>
                                                            <h6>
                                                                { t("we're setting up your account and will be in touch with you soon.") }
                                                            </h6>
                                                        </div>
                                                    </>
                                            }

                                        </div>

                                        <div style={ { position: "absolute", bottom: 0, left: '15%', zIndex: 1 } }>
                                            <p className="mb-0">
                                                © { new Date().getFullYear() } { config.appName }
                                            </p>
                                        </div>
                                    </div>
                                </Col>

                                <div className="col-xxl-8 col-lg-8 col-md-7"
                                     style={ {
                                         backgroundImage: `url(${ backgroundImage })`,
                                         backgroundSize: "cover",
                                         backgroundPosition: 'center -50px',
                                         backgroundColor: '#f7f7f7',
                                         backgroundRepeat: "no-repeat"
                                     } }>
                                    &nbsp;
                                </div>
                            </Row>
                        </Container>
                    </div>
                </>
            }

        </React.Fragment>
    );
};

export default withTranslation()(withRouter(RegistrationRequest));

RegistrationRequest.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
