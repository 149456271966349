import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, includes } from "lodash";
import { Button } from "primereact/button";
import { passwordRegex, utcDateToLocalDate, dateToUtcDate } from "../../../../utils/Helper";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import InputAttachment from "../../../../components/Shared/InputAttachment";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel: any;
}

type TInputForm = {
    id: string | null;
    supplier_id: string | null;
    first_name: string | null;
    last_name: string | null;
    username: string | null;
    email: string | null;
    password: string | null;
    password_confirmation: string | null;
    phone: string | null;
    is_active: boolean;
    is_suspended: boolean;
    role: any;
    profile: any;
    from_suspended_at: Date | string | null;
    to_suspended_at: Date | string | null;
    files: any[] | null;
    is_two_factor_authentication: boolean;
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        email: yup.string().email(t('the email must be a valid email address.')).required(t('the field is required.', { field: t('email') })).nullable(),
        first_name: yup.string().required(t('the field is required.', { field: t('first name') })).nullable(),
        last_name: yup.string().notOneOf([yup.ref('firstName')]).nullable(),
        username: yup.string()
        .matches(
            /^(?:[a-zA-Z0-9._-]{3,25}|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b)$/,
            t("invalid username.")
        )
        .max(200, t("the field must not be greater than max.", { max: 200, field: "username" }))
        .required(t("the field is required.", { field: "username" }))
        .nullable(),
        password: yup.string().when('id', {
            is: null,
            then: yup.string().required(t('the field is required.', { field: t('password') }))
            .matches(passwordRegex,
                t("password must contain at least 8 characters, one uppercase, one number and one special case character", { field: "password" })
            )
            .nullable(),
            otherwise: yup.string().notRequired().nullable(),
        }),
        password_confirmation: yup.string().when('id', {
            is: null,
            then: yup.string().required(t('the field is required.', { field: t('password confirmation') })).oneOf([yup.ref('password'), null], t('the field confirmation does not match.', { field: t('password') })).nullable(),
            otherwise: yup.string().notRequired().nullable(),
        }),
        phone: yup.string().nullable().matches(/^$|((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, t('the field value is not valid', { field: t('phone') })),
        role: yup.string().nullable(), // .required(t('the field is required.', { field: t('role') }))
        is_active: yup.boolean(),
        is_suspended: yup.boolean(),
        profile: yup.object().nullable(),
        from_suspended_at: yup.date(t('the field value is not valid', { field: t("from suspend date/time") })).nullable()
        .when("is_suspended", {
            is: true,
            then: yup.date().required(t('the field is required.', { field: "Date" }))
        }),
        // to_suspended_at: yup.date(t('the field value is not valid', { field: t("to suspend date/time") })).nullable()
        // .when("is_suspended", {
        //     is: true,
        //     then: yup.date().required(t('the field is required.', { field: "Date" }))
        // })
        // .min(yup.ref('from_suspended_at'), t('the field must be a date after', {
        //     field: t("to suspend date/time"),
        //     fieldAfter: t("from suspend date/time")
        // })),
        is_two_factor_authentication: yup.boolean(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    supplier_id: null,
    first_name: null,
    last_name: null,
    username: null,
    email: null,
    password: null,
    password_confirmation: null,
    phone: null,
    is_active: true,
    is_suspended: false,
    role: null,
    profile: null,
    files: null,
    from_suspended_at: null,
    to_suspended_at: null,
    is_two_factor_authentication: false,
};

function UserForm(props: IFormProps) {
    const { t, toastify, params, dropdowns, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    // const [searchParams] = useSearchParams();

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const { is_suspended, from_suspended_at, to_suspended_at } = payloads;
            let _payloads = payloads;

            if ( is_suspended ) {
                _payloads = {
                    ...payloads,
                    from_suspended_at: dateToUtcDate(from_suspended_at),
                    to_suspended_at: dateToUtcDate(to_suspended_at),
                };
            }

            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/users` : `/suppliers/users/${ payloads.id }` ),
                data: {
                    ..._payloads,
                    profile: payloads.profile?.id
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('user') }), "success");
            } else {
                toastify(t("record added", { item: t('user') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onClose = () => {
        setPanelState(false);
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }

        if ( props.onClose ) {
            props.onClose(
                !includes(['edit'], get(params, ['operation']))
            );
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/users/${ id }`
            });

            const { data } = response.data;
            const { roles } = data;
            delete data.roles;
            const formData: TInputForm = { ...data };

            formData.is_suspended = data.is_suspended > 0;
            formData.is_active = data.status > 0;
            formData.role = ( roles && roles.length > 0 ? Number(`${ roles[0].id }`) : null );
            formData.from_suspended_at = ( data.from_suspended_at ? utcDateToLocalDate(data.from_suspended_at, "YYYY-MM-DD HH:mm:ss") : null );
            formData.to_suspended_at = ( data.to_suspended_at ? utcDateToLocalDate(data.to_suspended_at, "YYYY-MM-DD HH:mm:ss") : null );
            setInitFormState(formData);
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        // setInitFormState(DEFAULT_FORM_STATE);

        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('user') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="first_name"
                                                   label={ t("first name") }
                                                   placeholder={ t("enter") + ' ' + t("first name").toLowerCase() }
                                                   isRequired
                                                   autoFocus />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="last_name"
                                                   label={ t("last name") }
                                                   placeholder={ t("enter") + ' ' + t("last name").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="username"
                                                   label={ t("username") }
                                                   placeholder={ t("enter") + ' ' + t("username").toLowerCase() }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="email"
                                                   label={ t("email") }
                                                   placeholder={ t("enter") + ' ' + t("email").toLowerCase() }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="role"
                                                   panelClassName={ "hide-p-toggler" }
                                                   options={ dropdowns.roles }
                                                   label={ t('role') }
                                                   placeholder={ t("pick") + ' ' + t("role").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="password"
                                                   type={ "password" }
                                                   label={ t("password") }
                                                   placeholder={ t("enter") + ' ' + t("password").toLowerCase() }
                                                   isRequired={ !props.values.id }
                                                   rounded />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="password_confirmation"
                                                   type={ "password" }
                                                   label={ t("password confirmation") }
                                                   placeholder={ t("enter confirm password") }
                                                   isRequired={ !props.values.id }
                                                   rounded />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="phone"
                                                   label={ t("phone") }
                                                   placeholder={ t("enter") + ' ' + t("phone").toLowerCase() }
                                                   rounded />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_active"
                                                   label={ t("email verified") }
                                                   trueLabel={ t("yes") }
                                                   falseLabel={ t("no") }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_two_factor_authentication"
                                                   label={ t("enable two factor login") }
                                                   trueLabel={ t("yes") }
                                                   falseLabel={ t("no") }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_suspended"
                                                   label={ t("want to suspend login?") }
                                                   trueLabel={ t("yes") }
                                                   falseLabel={ t("no") }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputAttachment }
                                                   name="profile"
                                                   label={ t("Upload") }
                                                   needBoolean
                                            />
                                        </Col>
                                        {
                                            props.values.is_suspended &&
                                            <>
                                                <Col sm={ 12 } md={ 6 }>
                                                    <Field component={ InputDateTime }
                                                           name="from_suspended_at"
                                                           type={ "datetime" }
                                                           label={ t("from suspend date/time") }
                                                           placeholder={ t("pick a date") }
                                                           isRequired
                                                           rounded
                                                    />
                                                </Col>
                                                <Col sm={ 12 } md={ 6 }>
                                                    <Field component={ InputDateTime }
                                                           name="to_suspended_at"
                                                           type={ "datetime" }
                                                           label={ t("to suspend date/time") }
                                                           placeholder={ t("pick a date") }
                                                           isRequired
                                                           rounded
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={() => operationCancel(null, null)}
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

UserForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(UserForm);
