import React, { useEffect, useLayoutEffect } from 'react';
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "./assets/styles/layout.scss";
import { supplierRoutes, authRoutes, adminRoutes, driverRoutes, defaultRoutes } from "./routes/allRoutes";
import { Routes, Route } from "react-router-dom";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import Auth from "./utils/Auth";
import NonAuthLayout from "./components/NonAuthLayout";
import ApplloLayout from "./components/ApplloLayout";
import { handleSyncRequestLoader } from "./utils/Helper";
import { PrimeReactProvider } from "primereact/api";
import { LayoutProvider } from "./contexts/LayoutContext";

function App() {
    const auth: any = new Auth();

    function getLayout() {
        let layoutCls: Object = ApplloLayout;

        return layoutCls;
    }

    const Layout: any = getLayout();

    useLayoutEffect(() => {
        handleSyncRequestLoader();
    }, []);

    return (
        <div id={ "layout-container-app" }>
            <PrimeReactProvider>
                <LayoutProvider>
                    <React.Fragment>
                        <Routes>
                            { defaultRoutes.map((route, idx) => (
                                <Route
                                    path={ route.path }
                                    element={
                                        <Authmiddleware>
                                            <Layout>{ route.component }</Layout>
                                        </Authmiddleware> }
                                    key={ idx }
                                />
                            )) }

                            { authRoutes.map((route, idx) => (
                                <Route
                                    path={ route.path }
                                    element={ <NonAuthLayout>{ route.component }</NonAuthLayout> }
                                    key={ idx }
                                />
                            )) }

                            { auth.isSupplierLevelUser() && supplierRoutes.map((route, idx) => (
                                <Route
                                    path={ route.path }
                                    element={
                                        <Authmiddleware>
                                            <Layout>{ route.component }</Layout>
                                        </Authmiddleware>
                                    }
                                    key={ idx }
                                />
                            )) }

                            { auth.isDriverLevelUser() && driverRoutes.map((route, idx) => (
                                <Route
                                    path={ route.path }
                                    element={
                                        <Authmiddleware>
                                            <Layout>{ route.component }</Layout>
                                        </Authmiddleware>
                                    }
                                    key={ idx }
                                />
                            )) }

                            { auth.isAdminLevelUser() && adminRoutes.map((route, idx) => (
                                <Route
                                    path={ route.path }
                                    element={
                                        <Authmiddleware>
                                            <Layout>{ route.component }</Layout>
                                        </Authmiddleware>
                                    }
                                    key={ idx }
                                />
                            )) }
                        </Routes>
                    </React.Fragment>
                </LayoutProvider>
            </PrimeReactProvider>
        </div>
    );
}

export default App;
