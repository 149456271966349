import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { get } from "lodash";
import { Toast } from "primereact/toast";
import { request } from "../../../../utils/Request";
import PrePaymentList from "./partials/PrePaymentList";
import { Sidebar } from "primereact/sidebar";

const DEFAULT_DROPDOWNS = {
    paymentMethods: [],
    paymentStatuses: [],
};

const PrePaymentIndex = (props: any) => {
    const { t, params } = props;
    const toast = useRef(null);
    const [dropdowns, setDropdowns] = useState<any>(null);

    const [moduleState, setModuleState] = useState<any>({
        page: 1,
        per_page: 20,
        search: null,
        initialized: false,
        sort_field: null,
        sort_order: null,
    });

    const getDropdowns = async () => {
        try {
            const response = await request({
                url: '/suppliers/clients/create',
                params: {
                    type: 'list',
                }
            });
            const { data } = response.data;
            setDropdowns({
                paymentMethods: data.payment_methods,
                paymentStatuses: data.payment_statuses,
            });
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        } finally {
            const defaultState = {
                page: 1,
                per_page: 20,
                search: null,
                initialized: true,
                sort_field: null,
                sort_order: null,
                filters: {
                    clients: [get(props, ['params', 'operationId'])]
                },
            }

            setModuleState(defaultState);
        }
    };

    useEffect(() => {
        async function init() {
            await getDropdowns();
        }

        init();
    }, [params.operationId]);

    const setQuery = (value: any) => {
        setModuleState(value);
    }

    const onClose = () => {
        props.onClose();
        setModuleState( {
            page: 1,
            per_page: 20,
            search: null,
            initialized: false,
            sort_field: null,
            sort_order: null,
            filters: [],
        });
    }

    return (
        <div className={ "module-container" }>
            <Toast ref={ toast } />
            <div className="layout-content">
                { ( moduleState && moduleState.initialized )
                    ?
                    <Sidebar
                        header={
                            <div className="bg-body-tertiary card-header">
                                <h5 className={ "card-title mt-0" }>{ t('balance adjustment') }</h5>
                                <p>{ t("using this form you can add or update the record") }</p>
                            </div>
                        }
                        visible={ moduleState.initialized } position="right"
                        onHide={ onClose } blockScroll={ true }
                        fullScreen closeOnEscape={ false }
                    >
                        <div style={ {
                            height: '3px',
                            background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                        } }>
                            <PrePaymentList
                                params={ params }
                                moduleState={ moduleState }
                                dropdowns={ dropdowns }
                                toastify={ props.toastify }
                                setQuery={ setQuery }
                            />
                        </div>
                    </Sidebar>
                    : <h6>{ t('loading') }</h6>
            }
        </div>
</div>
);
};

// Route.propTypes = {
//     t: PropTypes.any,
// };

    export default withTranslation()(PrePaymentIndex);
