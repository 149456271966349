import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { CardBody, CardHeader, Col, Row } from "react-bootstrap";
import { first, get, groupBy, orderBy, map, isEmpty, includes, assign, head, find } from "lodash";
import { request } from "../../../../utils/Request";
import { Card } from "primereact/card";
import { random, utcDateToLocalDate } from "../../../../utils/Helper";
import Auth from "../../../../utils/Auth";
import { Button } from "primereact/button";
import { useSearchParams } from "react-router-dom";
import SpotLoadStatus from "./SpotLoadStatus";
import { Accordion, AccordionTab } from "primereact/accordion";
import Location from "../../../../components/Common/Display/Location";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import { TabPanel, TabView } from "primereact/tabview";
import VinWithType from "../../../../components/Common/Display/VinWithType";

function SpotLoadOrder(props: any) {
    const { entity, t, single, orders, index, isFirstElement, counter, dropdowns } = props;

    if ( !entity ) {
        return <></>;
    }

    return (
        <Accordion className={"mt-2"} activeIndex={isFirstElement ? 0 : -1}>
            <AccordionTab header={
                <>
                { counter }. { t('route') } { single.route.title }
                </>
            }>
                <div>
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('from location') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            <Location item={ single.route.from_location } titled={ 0 } />
                        </Col>
                    </div>
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('to location') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            <Location item={ single.route.to_location } titled={ 0 } />
                        </Col>
                    </div>
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('driver') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            { single.load_order_drivers.map((item: any) => get(item, ['driver_name'])).join(', ') }
                        </Col>
                    </div>
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('transport vehicle') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            <Tooltip
                                tooltip={ `${ t("truck plate number") }` }>
                                { get(single.transport_vehicle, ['truck_plate_number']) }
                            </Tooltip>

                            <Tooltip
                                tooltip={ `${ t("trailer plate number") }` }>
                                <span className={ "ps-1 pe-1" }>|</span>
                                { get(single.transport_vehicle, ['trailer_plate_number']) }
                            </Tooltip>

                            {
                                get(single.transport_vehicle, ['sub_contractor', 'name']) &&
                              <span>
                                    <Tooltip
                                      tooltip={ `${ t("sub contractor") }` }>
                                        <span className={ "ps-1 pe-1" }>|</span>
                                        { get(single.transport_vehicle, ['sub_contractor', 'name']) }
                                    </Tooltip>
                                </span>
                            }
                        </Col>
                    </div>
                    { single.legal_entity &&
                      <div className={ "row" }>
                          <Col sm={ 12 } md={ 4 }>
                              <strong>{ t('legal entity') }: </strong>
                          </Col>
                          <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                              { ( single.legal_entity || {} ).company_name }
                          </Col>
                      </div>
                    }
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('estimated loading') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            { utcDateToLocalDate(single.estimate_loading_time, "DD.MM.YYYY hh:mm A") }
                        </Col>
                    </div>
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('estimated unloading') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            { utcDateToLocalDate(single.estimate_unloading_time, "DD.MM.YYYY hh:mm A") }
                        </Col>
                    </div>
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('status') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            <small className={ "text-uppercase" }>
                                <SpotLoadStatus status={ single.status } textable={ 1 } />
                            </small>
                        </Col>
                    </div>
                    { single.actual_loading_time &&
                      <div className={ "row" }>
                          <Col sm={ 12 } md={ 4 }>
                              <strong>{ t('actual loading') }: </strong>
                          </Col>
                          <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                              { utcDateToLocalDate(single.actual_loading_time, "DD.MM.YYYY hh:mm A") }
                          </Col>
                      </div>
                    }
                    { single.actual_unloading_time &&
                      <div className={ "row" }>
                          <Col sm={ 12 } md={ 4 }>
                              <strong>{ t('actual unloading') }: </strong>
                          </Col>
                          <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                              { utcDateToLocalDate(single.actual_unloading_time, "DD.MM.YYYY hh:mm A") }
                          </Col>
                      </div>
                    }
                    { single.in_transit_at &&
                      <div className={ "row" }>
                          <Col sm={ 12 } md={ 4 }>
                              <strong>{ t('operation loading') }: </strong>
                          </Col>
                          <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                              { utcDateToLocalDate(single.in_transit_at, "DD.MM.YYYY hh:mm A") }
                          </Col>
                      </div>
                    }
                    { single.delivered_at &&
                      <div className={ "row" }>
                          <Col sm={ 12 } md={ 4 }>
                              <strong>{ t('operation unloading') }: </strong>
                          </Col>
                          <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                              { utcDateToLocalDate(single.delivered_at, "DD.MM.YYYY hh:mm A") }
                          </Col>
                      </div>
                    }
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('destination location') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            <Location item={ entity.destination_location } titled={ false } />
                        </Col>
                    </div>
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('is billable?') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            { get(single, ['is_billable']) ? t("yes") : t("no") }
                        </Col>
                    </div>

                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('is billed?') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            { get(single, ['is_billed']) ? t("yes") : t("no") }
                        </Col>
                    </div>

                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('total km') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            { get(single, ['total_km']) }
                        </Col>
                    </div>
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('journey order') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            { get(single, ['group_index']) }
                        </Col>
                    </div>
                    <div className={ "row" }>
                        <Col sm={ 12 } md={ 4 }>
                            <strong>{ t('total') }: </strong>
                        </Col>
                        <Col sm={ 12 } md={ 8 } className={ "text-end" }>
                            { get(single, ['spot_load_route_price', 'total']) }
                        </Col>
                    </div>
                    <TabView>
                        <TabPanel header={ t("orders") }>
                            <div className="table-responsive mt-3">
                                <table className={"fl-table"}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{ t("vin number") }</th>
                                            <th>{ t("client") }</th>
                                            <th>{ t("order code") }</th>
                                            <th>{ t("brand") }</th>
                                            <th>{ t("model") }</th>
                                            <th>{ t("price") }</th>
                                            <th>{ t("in transit at") }</th>
                                            <th>{ t("delivered at") }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            orders.map((order: any, orderIndex: number) => {
                                                return (
                                                    <tr key={ orderIndex }>
                                                        <td>{ orderIndex + 1 }</td>
                                                        <td className={"text-left"}>
                                                            <VinWithType vinNumber={ get(order, ['order', 'vin_number']) } />
                                                            <div>
                                                                { order.order && order.order.order_journeys
                                                                .filter((i: any) => i.token <= order.o_j_token)
                                                                .map((item: any, index: number) => {
                                                                    return (
                                                                        <div key={ index }>
                                                                            { ( item.status === 'pending' ) &&
                                                                              <Tooltip tooltip={ t('pending') }>
                                                                                  <small><i
                                                                                    className="fas fa-circle text-success" /></small>
                                                                              </Tooltip>
                                                                            }
                                                                            { ( item.status === 'completed' ) &&
                                                                              <Tooltip tooltip={ t('complete') }>
                                                                                  <small><i
                                                                                    className="fas fa-circle text-secondary" /></small>
                                                                              </Tooltip>
                                                                            }
                                                                            { index + 1 }. <Location item={ item.from_location } titled={ 1 } /> ~ &nbsp; <Location item={ item.to_location } titled={ 1 } />
                                                                            <div>{ get(item.driver, ['name']) }</div>
                                                                            {
                                                                                get(item.transport_vehicle, ['truck_plate_number']) &&
                                                                              <div>{ get(item.transport_vehicle, ['truck_plate_number']) } | { get(item.transport_vehicle, ['trailer_plate_number']) }</div>
                                                                            }
                                                                        </div>
                                                                    );
                                                                }) }
                                                            </div>

                                                            { order.invoice_number &&
                                                              <div>
                                                                  <strong>{ t('invoice number') }: { order.invoice_number }</strong>
                                                              </div>
                                                            }
                                                        </td>
                                                        <td className={"text-left"}>{ get(order, ['client', 'name']) }</td>
                                                        <td className={"text-left"}>{ get(order, ['order', 'order_cd']) }</td>
                                                        <td className={ "text-capitalize text-left" }>
                                                            { get(order, ['order', 'brand', 'title']) }
                                                            { get(order, ['order', 'brand', 'alternate_title']) &&
                                                                ( get(order, ['order', 'brand', 'alternate_title']) )
                                                            }
                                                        </td>
                                                        <td className={ "text-capitalize text-left" }>
                                                            { get(order, ['order', 'brand_model', 'title']) }
                                                            { get(order, ['order', 'brand_model', 'version_code']) &&
                                                                ( get(order, ['order', 'brand', 'alternate_title']) )
                                                            }
                                                        </td>
                                                        <td>{ get(order, ['total']) }</td>
                                                        <td className={ "text-capitalize text-left" }>
                                                            { utcDateToLocalDate(get(order, ['in_transit_at'])) }
                                                        </td>
                                                        <td className={ "text-capitalize text-left" }>
                                                            { utcDateToLocalDate(get(order, ['delivered_at'])) }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </TabPanel>
                        <TabPanel header={t("status histories")}>
                            <TabView>
                                {
                                    orders.map((order: any, orderIndex: number) => {
                                        return (
                                            <TabPanel header={ order.order.vin_number } key={orderIndex}>
                                                <div className="table-responsive mt-3">
                                                    <table className={"fl-table"}>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{ t("author") }</th>
                                                                <th>{ t("status") }</th>
                                                                <th>{ t("note") }</th>
                                                                <th>{ t("created at") }</th>
                                                                <th>{ t("operation date") }</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                order.history.map((history: any, historyIndex: number) => {
                                                                    return (
                                                                        <tr key={ historyIndex }>
                                                                            <td>{ historyIndex + 1 }</td>
                                                                            <td>{ get(history, ['author', 'name']) }</td>
                                                                            <td className={ "text-uppercase" }>
                                                                                <SpotLoadStatus
                                                                                    status={ get(history, ['status']) } />
                                                                            </td>
                                                                            <td>
                                                                                <div>{ get(history, ['system_note']) }</div>
                                                                                { get(history, ['comment']) }
                                                                            </td>
                                                                            <td>
                                                                                { utcDateToLocalDate(history.created_at) }
                                                                            </td>
                                                                            <td>
                                                                                { utcDateToLocalDate(history.operation_date) }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPanel>
                                        );
                                    })
                                }
                            </TabView>
                        </TabPanel>
                        <TabPanel header={ t("transport documents") }>
                            <table className={"fl-table"}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{ t("title") }</th>
                                        <th>{ t("document types") }</th>
                                        {/*<th>{ t("vin(s)") }</th>*/}
                                        <th>{ t("size") }(KB)</th>
                                        <th className={ "text-end" }>{ t("action") }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        orders && [head(orders)]
                                        .map((order: any) => {
                                            return order.documents
                                            .filter(
                                                (document: any) =>
                                                    (
                                                        !includes(document.tags.map((tag: any) => tag.title), 'damage documents')
                                                        || document.tags.length > 1
                                                    )
                                            )
                                            .map((history: any, historyIndex: number) => {
                                                const _orders = orders.map((item: any) => item.order);
                                                return (
                                                    <tr key={ historyIndex }>
                                                        <td>{ historyIndex + 1 }</td>
                                                        <td>{ history.title }</td>
                                                        <td className={ "text-capitalize" }>{ history.tags && history.tags.map((tag: any) => tag.title).join(",") }</td>
                                                        {/*<td className={ "text-capitalize" }>*/}
                                                        {/*    { history.order_id.map((orderId: any) => get( find(_orders, {id: orderId}), ['vin_number'] )).join(", ") }*/}
                                                        {/*</td>*/}
                                                        <td>{ history.size > 0 ? ( history.size / 1024 ).toFixed(2) : 0 }</td>
                                                        <td className={ "text-end" }>
                                                            <a
                                                                href={ `${ process.env.REACT_APP_DOCUMENT_URL }/${ history.document_id }` }
                                                                className={ `btn waves-effect waves-light btn-info btn-sm ms-1` }
                                                                target={ "_blank" }
                                                                rel="noopener noreferrer">
                                                                <i className="fas fa-cloud-download-alt" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                );
                                            });
                                        })
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel header={ t("damage documents") }>
                            <table className={"fl-table"}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{ t("title") }</th>
                                        <th>{ t("vin(s)") }</th>
                                        <th>{ t("size") }(KB)</th>
                                        <th className={ "text-end" }>{ t("action") }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        orders && [head(orders)].map((order: any) => {
                                            return order.documents
                                            .filter((document: any) => includes(document.tags.map((tag: any) => tag.title), 'damage documents'))
                                            .map((history: any, historyIndex: number) => {
                                                const _orders = orders.map((item: any) => item.order);
                                                return (
                                                    <tr key={ historyIndex }>
                                                        <td>{ historyIndex + 1 }</td>
                                                        <td>{ history.title }</td>
                                                        <td className={ "text-capitalize" }>
                                                            { history.order_id.map((orderId: any) => get( find(_orders, {id: orderId}), ['vin_number'] )).join(", ") }
                                                        </td>
                                                        <td>{ history.size > 0 ? ( history.size / 1024 ).toFixed(2) : 0 }</td>
                                                        <td className={ "text-end" }>
                                                            <a
                                                                href={ `${ process.env.REACT_APP_DOCUMENT_URL }/${ history.document_id }` }
                                                                className={ `btn waves-effect waves-light btn-info btn-sm ms-1` }
                                                                target={ "_blank" }
                                                                rel="noopener noreferrer">
                                                                <i className="fas fa-cloud-download-alt" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                );
                                            });
                                        })
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </TabView>

                </div>
            </AccordionTab>
        </Accordion>
    );
}

const SpotLoadDetail = (props: any) => {
    const { t, toastify, params } = props;
    const auth = new Auth();
    const [entity, setEntity] = useState<any>(null);
    const [searchParams] = useSearchParams();
    const DEFAULT_DROPDOWNS = {
        documentTypes: [],
    };
    const [dropdowns, setDropdowns] = useState<any>(DEFAULT_DROPDOWNS);


    const getDropdowns = async () => {try {
            const response = await request({
                url: '/suppliers/spot/loads/create',
                params: {
                    type: 'show',
                    sub_type: 'suppliers'
                }
            });

            const { data } = response.data;
            setDropdowns({
                documentTypes: map(data.documentTypes, (obj: any) => assign({}, obj, { key: obj.id })),
            });
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        }
    };

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/spot/loads/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    const onClose = () => {
        // make sure back_to and back_with are not blank and redirect with all parameters
        if ( !isEmpty(searchParams.get('back_to'))) {
            // @ts-ignore
            // parameters will have r as a prefix so remove it first
            props.router.navigate(`/${searchParams.get('back_to')}`);
            return;
        }

        props.onClose();
    };

    const displayItems = (entity: any) => {
        let counter = 0;

        // const uniqueData = uniqBy(entity.load_orders, (item: any) => `${item.group_token}_${item.created_at}`);
        const sortedUniqueData = orderBy(entity.load_orders, ['group_token'], ['desc']);
        const orderedGroupedData: any = {};
        const groupedData = groupBy(sortedUniqueData, 'group_token');
        sortedUniqueData.forEach(item => {
            if ( !orderedGroupedData[item.group_token] ) {
                orderedGroupedData[item.group_token] = groupedData[item.group_token];
            }
        });

        return map(orderedGroupedData, (orders: any, index: any) => {
            const single: any = first(orders);
            const _index: any = random(15);
            counter++;

            return (
                <div key={ index }>
                    <SpotLoadOrder dropdowns={ dropdowns } entity={ entity } t={ t } single={ single } orders={ orders }
                                   index={ _index } counter={ counter }
                                   isFirstElement={ counter <= 1 } />
                </div>
            );
        });
    };

    useEffect(() => {
        setEntity(null);
        if ( searchParams ) {
            if ( includes(['spot-detail', 'detail'], searchParams.get('operation'))) {
                getDropdowns()
                // @ts-ignore
                getDetail(searchParams.get('operationId'));
            }
        }
    }, [params]);

    // fetch dropdown if needed because we are using this direct injected component into LoadList
    return (
        <div>
            <Row>
                <Col md={ 6 } lg={ 4 } sm={ 12 } className={"mt-2"}>
                    {
                        entity &&
                        <>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm={ 12 } md={ 6 }>
                                            <h5>
                                                { t('spot load') } { t('detail') }
                                                <br />
                                                <small className={ "ms-1" }>
                                                    #{ entity && entity.load_number }
                                                </small>
                                            </h5>
                                        </Col>
                                        <Col sm={ 12 } md={ 6 } className={ "text-end" }>
                                            <Button type={ "button" }
                                                    tooltip={ t("operation cancel") } className={ "ms-2" }
                                                    size={ "small" } severity={ "warning" } outlined
                                                    tooltipOptions={ { position: 'top' } } onClick={ onClose }>
                                                <i className="pi pi-arrow-left me-2" /> { t("back") }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm="12">
                                            <Row>
                                                <Col md="6" lg="6" sm="12">
                                                    <h6><span className="text-capitalize">{ t('created at') }</span>
                                                    </h6>
                                                </Col>
                                                <Col md="6" lg="6" sm="12">
                                                    <div
                                                      className="text-end">{ utcDateToLocalDate(entity.created_at) }</div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6" lg="6" sm="12">
                                                    <h6><span
                                                      className="text-capitalize">{ t('price type') }</span></h6>
                                                </Col>
                                                <Col md="6" lg="6" sm="12">
                                                    <div
                                                      className="text-end">{ entity.price_type }</div>
                                                    {
                                                        (entity.price_type === "per_load" || entity.price_type === "per_route") &&
                                                        <div
                                                          className="text-end">{ entity.s_price }</div>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6" lg="6" sm="12">
                                                    <h6><span
                                                      className="text-capitalize">{ t('cmr') }</span>
                                                    </h6>
                                                </Col>
                                                <Col md="6" lg="6" sm="12">
                                                    <div className="text-end">{ entity.cmr }</div>
                                                </Col>
                                            </Row>

                                              <Row>
                                                  <Col md="6" lg="6" sm="12">
                                                      <h6>
                                                        <span className="text-capitalize">{ t('invoice: merge route') }</span>
                                                      </h6>
                                                  </Col>
                                                  <Col md="6" lg="6" sm="12">
                                                      <div className="text-end text-uppercase">
                                                          { entity.per_load_merge_route ? t("yes") : t("no") }
                                                      </div>
                                                  </Col>
                                              </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className={"mt-3"}>
                                <CardHeader>
                                    <Row>
                                        <Col sm={ 12 } md={ 6 }>
                                            <h5>
                                                { t('status history') }
                                            </h5>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive p-2">
                                        <table className={ "fl-table w-full" }>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{ t("status") }</th>
                                                    <th>{ t("created at") }</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    entity.history.map((history: any, historyIndex: number) => {
                                                        return (
                                                            <tr key={ historyIndex }>
                                                                <td>{ historyIndex + 1 }</td>
                                                                <td className={ "text-uppercase" }>
                                                                    <SpotLoadStatus
                                                                        status={ get(history, ['status']) } />
                                                                </td>
                                                                <td>
                                                                    { utcDateToLocalDate(history.created_at) }
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </>
                    }
                </Col>
                <Col md={ 6 } lg={ 8 } sm={ 12 }>
                    {
                        entity &&
                      <Row>
                          <Col sm={ 12 }>
                              {
                                  ( entity && entity.load_orders ) && <> { displayItems(entity) }</>
                              }
                          </Col>
                      </Row>
                    }
                </Col>
            </Row>
        </div>

    );
};

SpotLoadDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(SpotLoadDetail));
