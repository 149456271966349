import React, { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProps } from "formik";
import backgroundImage from "../../assets/images/auth-bg.jpg";
import config from "../../config";
import { request } from "../../utils/Request";
import Auth from "../../utils/Auth";
import { AUTH_LOGIN } from "../../store/actions/auth";
import { get } from "lodash";
import withRouter from "../../components/Common/WithRouter";
import { Col, Container, Row } from "react-bootstrap";
import { InputField } from "../../components/Shared/InputField";
import { Button } from "primereact/button";
import { LayoutContext } from "../../contexts/LayoutContext";
import LoginOtpVerification from "./LoginOtpVerification";
import { Divider } from "primereact/divider";
import engImg from "../../assets/images/flags/us.svg";
import grImg from "../../assets/images/flags/gr.svg";
import rsImg from "../../assets/images/flags/rs.svg";
import itImg from "../../assets/images/flags/it.svg";
import spImg from "../../assets/images/flags/sp.svg";
import slImg from "../../assets/images/flags/sl.svg";
import Tooltip from "../../components/Common/Display/Tooltip";

interface LOGIN_FORM_STATE_TYPE {
    username: string | null,
    password: string | null,
    agree: boolean,
}

const DEFAULT_LOGIN_FORM_STATE: LOGIN_FORM_STATE_TYPE = {
    username: null,
    password: null,
    agree: true
};

const validationSchema = (t: any) => Yup.object().shape({
    username: Yup.string()
    .max(100, t("the field must not be greater than max.", { max: 300, field: "username" }))
    .required(t("the field is required.", { field: "username" })).nullable(),
    password: Yup.string().required(t("the field is required.", { field: "password" })).nullable(),
    agree: Yup.boolean()
});

const Login = (props: any) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const formRef: any = useRef();
    const navigate = useNavigate();
    const [visible, setVisible] = useState<boolean>(false);
    const [visibleOtpInput, setVisibleOtpInput] = useState<boolean>(false);
    const [userIdForOtp, setUserIdForOtp] = useState<string|null>(null);
    const [initFormState, _] = useState<LOGIN_FORM_STATE_TYPE>(DEFAULT_LOGIN_FORM_STATE);
    const { t } = props;
    const auth = new Auth();
    const {setLayoutConfig} = useContext(LayoutContext);

    document.title = `${ t("Login") } | ${ config.appNameForTitle }`;

    const onSubmit = async (values: LOGIN_FORM_STATE_TYPE, { setErrors }: any): Promise<void> => {
        try {
            const response = await request({
                method: 'POST',
                url: `/login`,
                data: values,
                withoutAuth: 1
            });

            const { data } = response;
            if ( data.data.is_two_factor_authentication > 0 && data.data.id ) {
                setVisibleOtpInput(true);
                setUserIdForOtp(data.data.id);
            } else {
                auth.setUser(data.data);
                dispatch({ type: AUTH_LOGIN });
                setLayoutConfig(data.data.app_settings);
                setTimeout(() => window.location.href = '/dashboard', 500);
            }
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            }
        }
    };

    const handleTermsChange = (e: any) => {
        const base: any = get(formRef, ['current']);
        if ( base ) {
            base.setFieldValue('agree', e);
        }
    };

    const onClickLangChange = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        if ( auth.getToken() !== "undefined" && auth.getUser() ) {
            navigate('/dashboard');
        } else {
            setVisible(true);
        }
    }, []);

    return (
        <React.Fragment>
            {
                visible &&
                <>
                    <div className="auth-page">
                        <Container fluid className="p-0">
                            <Row className="g-0">
                                <Col lg={ 4 } md={ 5 } className="col-xxl-4">
                                    <div className="px-5 min-h-screen flex align-items-center">
                                        <div
                                          className="w-full border-1 surface-border surface-card border-round py-2 px-4 md:px-7 z-2">
                                            <div className="mb-0 mb-md-0 text-center">
                                                <Link to="/dashboard" className="d-block auth-logo">
                                                    <img
                                                      src={ `${ process.env.REACT_APP_API_URL }/logo/alcolm-logo-blue.png` }
                                                      height="80"
                                                      alt="Alcolm"
                                                    />{ " " }
                                                </Link>
                                            </div>

                                            <div className="mt-3 text-center">
                                                <span className="text-600 font-medium ">
                                                    { t("please enter your details to start session") }
                                                </span>
                                            </div>
                                            <div className={ "mt-4 pb-4" }>
                                                {
                                                    ( !visibleOtpInput && !userIdForOtp ) ?
                                                        <>
                                                            <Formik
                                                                innerRef={ formRef }
                                                                enableReinitialize={ true }
                                                                initialValues={ initFormState }
                                                                validationSchema={ validationSchema(t) }
                                                                onSubmit={ onSubmit }
                                                            >
                                                                { (props: FormikProps<any>) => {
                                                                    return (
                                                                        <Form onSubmit={ props.handleSubmit }>
                                                                            <Field component={ InputField }
                                                                                   name="username"
                                                                                   label={ t("username") }
                                                                                   isRequired="true"
                                                                                   placeholder={ t("enter username") }
                                                                                   autoComplete="off"
                                                                                   autoFocus />

                                                                            <Field component={ InputField }
                                                                                   type={ "password" }
                                                                                   name="password"
                                                                                   label={ t("password") }
                                                                                   isRequired="true"
                                                                                   placeholder={ t("enter password") }
                                                                                   autoComplete="new-password"
                                                                            />

                                                                            <div className="form-check">
                                                                                <input
                                                                                    className="form-check-input d-inline"
                                                                                    name={ "agree" }
                                                                                    type="checkbox"
                                                                                    id="terms"
                                                                                    checked={ props.values.agree }
                                                                                    onChange={ (e: any) => handleTermsChange(e.target.checked) }
                                                                                />
                                                                                <div className={ "d-inline" }>
                                                                                    { t("by logging in, i agree to the") }
                                                                                    <a href="https://alcolm.com/docs/PrivacyPolicyCloudWervicesEN.pdf"
                                                                                       target={ "_blank" }
                                                                                       rel="noreferrer">
                                                                                        &nbsp; { t("privacy policy") }&nbsp;
                                                                                    </a>
                                                                                    { t('and') }
                                                                                    <a href={ `${ process.env.REACT_APP_API_URL }/terms/terms.pdf` }
                                                                                       target={ "_blank" }
                                                                                       rel="noreferrer">
                                                                                        &nbsp; { t('terms & conditions') }
                                                                                    </a>
                                                                                </div>
                                                                            </div>

                                                                            <div className={ "mt-3" }>
                                                                                <Button label={ t("log in") }
                                                                                        type={ "submit" }
                                                                                        disabled={ props.isSubmitting || !props.values.agree }
                                                                                        className={ "w-full" }
                                                                                        size={ "small" }
                                                                                        loading={ props.isSubmitting }
                                                                                />
                                                                            </div>
                                                                        </Form>
                                                                    );
                                                                } }
                                                            </Formik>
                                                            <div className="mt-5 text-center">
                                                                <p className="text-muted mb-0">
                                                                    { t("lost your password?") } { " " }
                                                                    <Link
                                                                        to="/forget/password"
                                                                        className="text-primary fw-semibold"
                                                                    >
                                                                        { " " }
                                                                        { t("click to recover") }{ " " }
                                                                    </Link>{ " " }
                                                                </p>

                                                                <Divider align="center">
                                                                    <span className="p-tag">{ t('or') }</span>
                                                                </Divider>
                                                                <p className="text-muted mb-0">
                                                                    <Link
                                                                        to="/account/request"
                                                                        className="text-primary fw-semibold"
                                                                    >
                                                                        { t("register here") }
                                                                    </Link>
                                                                </p>
                                                                <Divider align="center">
                                                                    <span
                                                                        className="p-tag">{ t("change language") }</span>
                                                                </Divider>
                                                                <Row>
                                                                    <Col
                                                                        className={ "text-center" }>
                                                                        <Tooltip
                                                                            tooltip={ t('click: to switch language to english') }>
                                                                            <a
                                                                                className="cursor-pointer p-2"
                                                                                onClick={ () => onClickLangChange("eng") }>
                                                                                <img src={ engImg }
                                                                                     alt="English"
                                                                                     width={ 30 }
                                                                                     height={ 20 } />
                                                                            </a>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            tooltip={ t('click: to switch language to german') }>
                                                                            <a
                                                                                className="cursor-pointer p-2"
                                                                                onClick={ () => onClickLangChange("gr") }>
                                                                                <img src={ grImg }
                                                                                     alt="German"
                                                                                     width={ 30 }
                                                                                     height={ 20 } />
                                                                            </a>
                                                                        </Tooltip>
                                                                        {/*<Tooltip*/}
                                                                        {/*    tooltip={ t('click: to switch language to russian') }>*/}
                                                                        {/*    <a*/}
                                                                        {/*        className="cursor-pointer p-2"*/}
                                                                        {/*        onClick={ () => onClickLangChange("rs") }>*/}
                                                                        {/*        <img src={ rsImg }*/}
                                                                        {/*             alt="Russian"*/}
                                                                        {/*             width={ 30 }*/}
                                                                        {/*             height={ 20 } />*/}
                                                                        {/*    </a>*/}
                                                                        {/*</Tooltip>*/}
                                                                        <Tooltip
                                                                            tooltip={ t('click: to switch language to italian') }>
                                                                            <a
                                                                                className="cursor-pointer p-2"
                                                                                onClick={ () => onClickLangChange("it") }>
                                                                                <img src={ itImg }
                                                                                     alt="Italian"
                                                                                     width={ 30 }
                                                                                     height={ 20 } />
                                                                            </a>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            tooltip={ t('click: to switch language to spanish') }>
                                                                            <a
                                                                                className="cursor-pointer p-2"
                                                                                onClick={ () => onClickLangChange("sp") }>
                                                                                <img src={ spImg }
                                                                                     alt="Spanish"
                                                                                     width={ 30 }
                                                                                     height={ 20 } />
                                                                            </a>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            tooltip={ t('click: to switch language to slovenian') }>
                                                                            <a
                                                                                className="cursor-pointer p-2"
                                                                                onClick={ () => onClickLangChange("sl") }>
                                                                                <img src={ slImg }
                                                                                     alt="Slovenian"
                                                                                     width={ 30 }
                                                                                     height={ 20 } />
                                                                            </a>
                                                                        </Tooltip>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </>
                                                        : <LoginOtpVerification id={ userIdForOtp } />
                                                }
                                            </div>
                                        </div>
                                        <div style={ { position: "absolute", bottom: 0, left: '15%', zIndex:1 } }>
                                            <p className="mb-0">
                                                © { new Date().getFullYear() } { config.appName }
                                            </p>
                                        </div>
                                    </div>
                                </Col>

                                <div className="col-xxl-8 col-lg-8 col-md-7"
                                     style={ {
                                         backgroundImage: `url(${ backgroundImage })`,
                                         backgroundSize: "cover",
                                         backgroundPosition: 'center -50px',
                                         backgroundColor: '#f7f7f7',
                                         backgroundRepeat: "no-repeat"
                                    }}>
                                    &nbsp;
                                </div>
                            </Row>
                        </Container>
                    </div>
                </>
            }

        </React.Fragment>
    );
};

export default withTranslation()(withRouter(Login));

Login.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
